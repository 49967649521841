.orders-list-block {
  width: 100%;
  border: 1px solid #73788c;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  &__header {
    background-color: #fff;
    width: 100%;
    max-height: 230px;
    padding: 10px;
    border-bottom: 1px solid rgba(91, 93, 101, 0.4);

    &_title {
      display: flex;
      align-items: center;
      margin-bottom: 7px;
      width: 100%;

      > img {
        margin-right: 10px;
      }

      & h2 {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 20px;
      }

      .refresh-button {
        width: 40px;
        height: 40px;
        background-color: #eee;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: white;

        &:hover {
          background-color: rgba(25, 118, 210, 0.2);
        }
      }
    }

    &_subtitle {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      color: #73788c;
      font-size: 14px;
      font-weight: 400;

      .search {
        align-self: center;
        background-color: rgba(237, 237, 237, 0.6);
        border-radius: 4px;
        padding: 7px 5px;
        display: flex;
        align-items: center;
        cursor: pointer;

        label {
          cursor: pointer;
        }

        img {
          margin-right: 10px;
        }

        input {
          border: 0px;
          background-color: transparent;
          font-family: "Roboto", sans-serif;
          font-weight: 400;
          font-size: 14px;
          letter-spacing: 0.5px;
          color: #5b5d65;
        }
      }

      .dropdown-wraper {
        width: calc(60% - 10px);
        position: relative;

        @media screen and (max-width: 700px) {
          width: calc(100% - 10px);
          margin-top: 10px;
        }
      }

      .filter-dropdown {
        border-radius: 4px;
        border: 1px solid rgba(91, 93, 101, 1);
        padding: 7px 7px;
        display: flex;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition:
          background-color 0.3s ease,
          border 0.3s ease;
        margin: 10px 0;

        span {
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          color: rgba(91, 93, 101, 1);
          transition: color 0.3s ease;
        }

        svg {
          fill: rgba(91, 93, 101, 1);
          transition: fill 0.3s ease;
        }

        &:hover {
          background-color: #eee;
        }
      }

      .filter-dropdown.opened {
        border: 1px solid #9978e2;
        background-color: rgba(153, 120, 226, 0.1) !important;

        svg {
          fill: #9978e2;
        }

        span {
          color: #9978e2;
        }
      }

      .dropdown-list {
        position: absolute;
        top: 32px;
        z-index: 100;
        width: 100%;
        background-color: white;
        border-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 0px;
        overflow: auto;
        transition: max-height 0.2s ease-in-out;

        &::-webkit-scrollbar {
          background-color: transparent;
          width: 2px;
          border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #9978e2;
          border-radius: 2px;
        }

        .gray-line {
          width: 90%;
          height: 1px;
          background-color: rgba(91, 93, 101, 1);
        }

        .line {
          margin: 5px 0;
          width: 100%;
          padding: 8px 15px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          span {
            font-family: "Roboto", sans-serif;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.5;
          }

          &:hover {
            background-color: #eee;
          }
        }
      }

      .dropdown-list.opened {
        max-height: 150px;
        padding-top: 5px;
        border: 1px solid #9978e2;
      }
    }
  }

  &__body {
    background-color: rgba(246, 248, 250, 1);
    position: relative;
    flex: 1 1 auto;
    width: 100%;

    .date-line {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 0;

      span {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.6px;
        color: rgba(51, 63, 78, 1);
      }
    }

    &_scrolled {
      height: calc(70vh - 118px);
      min-height: 210px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #9978e2;
        border-radius: 2px;
      }

      &_next {
        display: flex;
        padding-bottom: 20px;
        padding-top: 10px;
        cursor: pointer;
        align-items: center;
        color: #9978e2;
        width: 100%;
        background-color: #fff;
        justify-content: center;

        svg {
          transform: rotate(90deg);
          margin-right: 10px;
        }

        transition: background-color 0.2s ease;
      }

      &_previous {
        display: flex;
        cursor: pointer;
        align-items: center;
        padding: 5px 0;
        border-bottom: 1px solid #9978e2;
        color: #9978e2;
        width: 100%;
        background-color: #fff;
        justify-content: center;

        svg {
          transform: rotate(-90deg);
          margin-right: 10px;
        }

        transition: background-color 0.2s ease;
      }

      &_next:hover,
      &_previous:hover {
        background-color: #dceaff;
      }
    }

    .scrollable-shadow {
      width: 100%;
      height: 20px;
      position: absolute;
      bottom: 0;
      background: linear-gradient(
        180deg,
        #73788c 0%,
        rgba(115, 120, 140, 0) 100%
      );
      opacity: 0.2;
      transform: rotate(180deg);
    }
  }

  &__footer {
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 6px 20px;
    margin-top: auto;

    &_label {
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.5px;
      color: #73788c;
      opacity: 0.8;
    }

    &_price {
      font-weight: 700;
      font-size: 20px;
      color: #333f4e;
    }
  }
}
