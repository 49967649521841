.shops-list-block {
  width: 100%;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  &__body {
    width: 100%;
    flex: 1 1 auto;
    height: 100%;
    //background-color: #E8EDF3;
    position: relative;

    &_scrolled {
      height: calc(70vh - 32px);

      @media screen and (max-width: 1308px) and (min-width: 1024px) {
        height: calc(70vh - 52px);
      }

      min-height: 266px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #9978e2;
        border-radius: 2px;
      }
    }

    .scrollable-shadow {
      width: 100%;
      height: 20px;
      position: absolute;
      bottom: 0;
      background: linear-gradient(
        180deg,
        #73788c 0%,
        rgba(115, 120, 140, 0) 100%
      );
      opacity: 0.2;
      transform: rotate(180deg);
    }

    &__pagination {
      width: 100%;
      padding: 10px 0;
      padding-bottom: 30px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      p {
        color: #9978e2;
        font-weight: bold;
      }
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    margin-top: auto;
    cursor: pointer;

    p {
      letter-spacing: 0.5px;
      color: #73788c;
      margin-left: 10px;
      font-size: 16px;
    }
  }
}
