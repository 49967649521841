.IframePosterConfirmPage {
  margin-left: 30%;
  font-family: Arial, serif;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;

  @media screen and (max-width: 800px) {
    padding: 30px;
  }

  .IframePosterConfirm {
    width: 60%;
    padding-top: 40px;
    text-align: center;

    .IframePosterConfirm_Title {
      font-weight: 500;
      font-size: 1.8rem;
      text-align: center;
      display: flex;
      justify-content: center;
    }

    .IframePosterConfirm_pBox {
      margin-top: 20px;
      line-height: 1.4;

      a {
        color: #0000ee;
      }
      a:visited,
      a:focus,
      a:hover {
        color: #0000ee;
      }
    }

    .IframePosterConfirm_Button {
      transition: ease-in-out 0.1s;
      text-decoration: none;
      width: 100%;
      min-width: 200px;
      max-width: 370px;
      height: 50px;
      background-color: #f6a005;
      border-radius: 40px;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.2px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 30px auto 0 auto;
      border: none;
      cursor: pointer;
      &:hover {
        background-color: orange;
      }
    }
  }
}
