.consist-item-container {
  width: 100%;
  border-bottom: 1px solid #73788c;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;

  &_left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 5px;
    max-width: 70%;
    &_top {
      display: flex;
      flex-direction: row;
      align-items: center;
      h2 {
        font-weight: 700;
        font-size: 18;
        color: #333f4e;
      }
      div {
        margin-left: 7px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: transparent;
        transition: background-color 0.5s ease-out;
        cursor: pointer;
        &:hover {
          background-color: #9978e2;
        }
        img {
          width: 13.3px;
          height: 13.3px;
        }
      }
    }
    &_bottom {
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      p {
        color: #333f4e;
        letter-spacing: 0.2px;
        font-size: 14px;
        font-weight: 400;
      }
      span {
        color: #333f4e;
        letter-spacing: 0.2px;
        font-size: 14px;
        font-weight: 700;
        margin-left: 3px;
      }
    }
  }

  &_right {
    display: flex;
    flex-direction: row;
    align-items: center;

    &_count {
      display: flex;
      align-items: center;
      .icon {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      input {
        border-radius: 4px;
        width: 50px;
        border: 2px solid #abb3d1;
        padding: 3px 8px;
        font-size: 16px;
        line-height: 17.6px;
        color: #333f4e;
        font-weight: 500;
        text-align: right;
        margin-left: 5px;
        margin-right: 5px;
        @media screen and (min-width: 1600px) {
          width: 100px;
        }
      }
    }

    &_price {
      width: 80px;
      p {
        font-weight: 700;
        font-size: 18px;
        text-align: right;
        color: #333f4e;
      }
    }
  }
}
