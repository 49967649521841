.select-category-component {
  width: 200px !important;

  .MuiInputBase-input {
    padding: 10px 10px !important;
    font-size: 18px;
  }

  .placeholder {
    font-size: 14px;
    color: #aaa;
    width: 100%;
  }

  li.disabled {
    background-color: rgb(139, 137, 137);
  }
}
