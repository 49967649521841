.personal-item-container {
  width: 100%;
  border-bottom: 1px solid #73788c;
  border-left: 6px solid transparent;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  padding-right: 10px;
  cursor: pointer;

  &_left {
    max-width: 70%;
    p {
      color: #333f4e;
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
    }
    span {
      color: #6f7486;
      font-size: 14px;
      letter-spacing: 0.5px;
      font-weight: normal;
    }
    &_p_sl {
      color: #9978e2 !important;
      font-weight: 600 !important;
    }
  }
  &_right {
    max-width: 25%;
    padding: 10px 10px;
    border: 1px solid #eb5757;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #eb5757;
      font-size: 14px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
    }
  }
}

.personal-item-container-selected {
  border-left: none;
  border-left: 6px solid #9978e2;
  background-color: #e9f2fe;
}
