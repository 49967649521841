.Prompt {
  z-index: 999;
  position: fixed;
  left: 0;
  top: 8px;
  width: 100%;
  display: flex;
  justify-content: center;

  .PromptBox {
    padding: 13px 42px;
    background-color: #dd3c3c;
    border-radius: 12px;
    width: 386px;
    min-height: 2px;
    box-sizing: border-box;
    text-align: left;
    font-weight: 300;
    color: #fff;
    position: relative;

    .PromptIcon {
      position: absolute;
      left: 15px;
      top: 13px;
    }
  }
}
