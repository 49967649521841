.notifications-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  @media screen and (max-width: 1024px) {
    margin-top: 0px;
  }

  .notifications-block {
    width: calc(100% - 40px);
    margin: 20px 0;
    border: 1px solid rgb(140, 143, 155);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
  }

  .select-filter-block {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 30px;

    .label {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 5px;
      }

      span {
        font-size: 14px;
        color: rgba(91, 93, 101, 1);
      }
    }

    .notifications-filters {
      display: flex;
      align-items: center;
      margin-left: 20px;
      height: 50px;

      @media screen and (max-width: 1160px) {
        margin-left: 12px;
      }

      & p {
        color: #73788c;
      }

      &_item {
        margin-left: 10px;
        display: flex;
        align-items: center;
        height: 50px;

        & p {
          text-decoration: none;
          color: #333f4e;
          font-weight: 400;
          font-size: calc(12px + 0.1vw);
          padding: 15px 15px;
          display: flex;
          align-items: center;

          svg {
            width: 20px;
            height: 17px;
            margin-right: 3px;
          }

          @media screen and (max-width: 1160px) {
            padding: 8px 10px !important;
          }

          cursor: pointer;
          border-bottom: 2px solid #c8cacd;
        }

        .dis_border {
          transition: background-color 0.2s ease;
        }

        .dis_border:hover {
          background-color: rgb(241, 241, 241);
          border-bottom: 4px solid #a6a7a8;
        }

        .active_border {
          border-bottom: 4px solid #9978e2;
          color: #9978e2;
        }
      }
    }

    .notifications-filters-small {
      display: none;
      flex-direction: row;
      align-items: center;
      margin-left: 10px;

      span {
        margin-right: 10px;
        color: #73788c;
        font-size: 14px;
      }

      div {
        height: 50%;
        color: #9978e2;
        background-color: #eee !important;

        div {
          div {
            padding: 0;
            padding: 10px 5px;
            padding-right: 15px;
            min-width: 200px;
            display: flex;
            align-items: center;

            svg {
              margin-right: 5px;
              width: 20px;
              height: 17px;
            }
          }

          svg.MuiSvgIcon-root {
            right: 0;
            margin-left: 20px;
            color: #9978e2;
          }
        }
      }

      .MuiSelect-filled {
        width: 70%;
      }
    }

    @media screen and (max-width: 1024px) {
      .notifications-filters {
        display: none;
      }

      .notifications-filters-small {
        display: flex;
      }
    }
  }

  .notifications-block-header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    padding: 7px 16px;
    height: 51px;
    border-bottom: 1px solid rgb(140, 143, 155);

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: 20px;
    }

    .clear-button {
      display: flex;
      align-items: center;
      border: 1px solid rgb(174, 177, 192);
      background-color: transparent;
      padding: 8px 10px;
      border-radius: 3px;
      cursor: pointer;
      transition:
        background-color 0.3s linear,
        border 0.3s ease;

      &:hover {
        background-color: #eee;
      }

      svg {
        margin-right: 10px;

        @media screen and (max-width: 1160px) {
          margin-right: 6px;
        }
      }

      span {
        font-weight: 400;
        font-size: 16px;

        @media screen and (max-width: 1160px) {
          font-size: 13px;
        }
      }

      &:hover {
        border: 1px solid #9978e2;
      }
    }

    .delete-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 10px;
      border-radius: 3px;
      border: 1px solid #afb3c2;
      background-color: transparent;
      cursor: pointer;
      margin-left: 20px;
      transition: background-color 0.2s linear;

      svg {
        fill: #9978e2;
        margin-right: 10px;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        color: rgba(51, 63, 78, 1);
      }

      &:hover {
        background-color: #eee;
      }
    }

    .archive-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 10px;
      border-radius: 3px;
      border: none;
      background-color: #9978e2;
      cursor: pointer;
      margin-left: 20px;
      transition: background-color 0.2s linear;

      svg {
        margin-right: 10px;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        color: white;
      }

      &:hover {
        background-color: #7457aa;
      }
    }
  }

  .notifications-block-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .notifications-block-body-header {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(140, 143, 155);

    > * {
      font-size: 14px;
      font-weight: 400;
      color: #767883;
    }

    .notf-checkbox {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 5px;

      input {
        width: 15px;
        height: 15px;
      }
    }

    .notf-message-type {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 20px;
    }

    .notf-message {
      width: calc(60% - 170px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 20px;
    }

    .notf-prioritet {
      width: calc(15%);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 20px;
    }

    .notf-date {
      width: calc(25%);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 20px;
    }

    .notf-setting {
      width: calc(50px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 5px;
    }
  }

  .notifications-block-body-items {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #9978e2;
    }

    .item.selecting:hover {
      background-color: #f4f6fa;
    }

    .item.selected {
      background-color: #e0ebfa;
    }

    .item:last-child {
      border-bottom: 0px solid #000;
    }

    .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid rgb(140, 143, 155);
      transition: background-color 0.2s linear;
      padding: 15px 0;
      cursor: pointer;

      .notf-checkbox {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 5px;

        input {
          width: 15px;
          height: 15px;
        }
      }

      .notf-message-type {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 20px;

        .mt-icon-div {
          padding: 10px 20px;
        }

        .mt-icon-div.disabled {
          border-left: 2px solid rgba(130, 130, 130, 1);

          svg {
            fill: rgba(130, 130, 130, 1);
          }
        }

        .mt-icon-div.order-new {
          border-left: 3px solid rgba(42, 141, 3, 1);
        }

        .mt-icon-div.limit {
          border-left: 3px solid rgba(233, 148, 70, 1);
        }

        .mt-icon-div.ends {
          border-left: 3px solid rgba(232, 4, 0, 1);
        }

        .mt-icon-div.payed {
          border-left: 3px solid rgba(168, 59, 206, 1);
        }

        .mt-icon-div.info {
          border-left: 3px solid rgba(85, 125, 200, 1);
        }
      }

      .notf-message {
        width: calc(60% - 170px);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 20px;

        .message {
          font-weight: 500;
          font-size: 16px;
          font-family: "Roboto", sans-serif;
          color: #333f4e;
          padding: 5px;
          border-radius: 5px;
        }

        .message.active {
          color: #9978e2 !important;
          text-decoration: underline;

          &:hover {
            background-color: #c4dbfc;
          }
        }
      }

      .notf-prioritet {
        width: calc(15%);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 20px;

        .prioritet {
          display: flex;
          align-items: center;
          justify-content: center;

          .circle {
            width: 7px;
            height: 7px;
            border-radius: 50%;
          }

          span {
            margin-left: 10px;
            color: #333f4e;
            letter-spacing: 0.2;
            font-size: 14px;
          }
        }
      }

      .notf-date {
        width: calc(25%);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 20px;

        .date {
          font-weight: 400;
          font-size: 16px;
        }
      }

      .notf-setting {
        width: calc(50px);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 5px;

        .icon {
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          cursor: pointer;

          svg {
            transition: fill 0.2s linear;
          }

          &:hover {
            background-color: rgba(25, 118, 210, 0.2);
          }

          &:hover svg {
            fill: blue;
          }
        }
      }
    }

    .item.disabled {
      .notf-message-type {
        span {
          color: rgb(103, 105, 114);
        }
      }

      .notf-message {
        .message {
          color: rgb(157, 159, 172);
        }
      }

      * {
        color: rgb(157, 159, 172) !important;
      }
    }

    .empty-item {
      width: 100%;
      background-color: rgb(250, 249, 249);
      height: 400px;
      display: flex;
      justify-content: center;
      padding-top: 40px;

      span {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        letter-spacing: 0.4;
        color: rgb(36, 35, 35);
      }
    }

    .load-next-part {
      width: 100%;
      background-color: #6eabff;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background-color 0.3s ease;

      span {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        letter-spacing: 0.4;
        color: white;
      }

      &:hover {
        background-color: #4692fc;
      }
    }

    .loading-item {
      height: 400px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
