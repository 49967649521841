@import "../../../styles/variables";

.IframePosterMerchantPage {
  margin-left: 30%;
  font-family: Arial, serif;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;

  .IframePosterMerchant {
    width: 100%;

    .IframePosterStoresHeaderWrap {
      /*position: fixed;
      right: 0;
      top: 0;*/
      height: 50px;
      width: 100%;
      background-color: red;

      .IframePosterStoresHeader {
        height: 50px;
        width: 100%;
        background-color: #ededed;
        box-shadow: 0 0 16px 1px rgba(69, 77, 88, 0.3);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;

        .HeaderImportButton {
          transition: ease-in-out 0.1s;
          width: 160px;
          height: 30px;
          background-color: $main_bg-purple;
          border-radius: 40px;
          color: #fff;
          font-weight: 700;
          font-size: 12px;
          letter-spacing: 0.2px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          cursor: pointer;
          &:hover {
            background-color: $main_bg-purple-darkest-hover;
          }
          &:active {
            background-color: $main_bg-purple;
          }
        }
      }
    }

    .IframePosterStoresContent {
    }
  }
}
