@import "src/styles/variables.scss";

.user-profile-container {
  width: 100%;
  border: 1px solid #73788c;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  min-height: calc(70vh + 133px);
  height: 100%;
  min-height: 406px;
  padding: 26px 20px 20px 20px;
  overflow-y: auto;

  & h2 {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 20px;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9978e2;
    border-radius: 2px;
  }

  &_header {
    margin-top: 30px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;

    img {
      max-width: 120px;
      max-height: 120px;
      border-radius: 5px;
      margin-right: 10px;
    }
    &_initials {
      font-weight: 600;
      font-size: 24px;
      margin-right: 20px;
    }
  }

  &_item {
    display: flex;
    flex-direction: column;
    margin: 7px 0;

    p {
      color: #6f7486;
      font-weight: 400;
      font-size: 14px;
    }

    span {
      color: #333f4e;
      font-weight: 500;
      font-size: 22px;
    }
  }

  &_footer {
    display: flex;
    width: 100%;
    margin-top: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .active {
      border: none;
      padding: 7px 15px;
      margin-right: 18px;
      color: #fff;
      background-color: #257df7;
      border: 2px solid transparent;
      cursor: pointer;
    }

    .active:hover {
      background-color: #1c61c0;
    }

    .red {
      cursor: pointer;
      padding: 3px 10px;
      margin-right: 18px;
      color: #c73f3f;
      border: 2px solid #c73f3f;
      background-color: transparent;
      margin-left: 50px;
    }

    .red:hover {
      background-color: transparent;
      border: 2px solid #de5050;
    }

    .buttonPurple {
      padding: 5px 10px;
      border: none;
      background-color: $main_bg-purple-darkest;
      color: #fff;
      cursor: pointer;
    }

    & button:hover {
      background-color: $main_bg-purple-darkest-hover;
    }
  }
}
