.create-prod-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;

  .close-div {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p {
      margin-right: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #6f7486;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
  }

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    font-size: 24px;
    padding-left: 20px;
    font-weight: normal;

    img {
      margin-right: 10px;
    }

    p {
      color: #000;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    @media screen and (min-width: 1600px) {
      font-size: 30px;
    }

    @media screen and (max-width: 1024px) {
      font-size: 20px;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    .editor {
      background-color: #fff;
      padding: 0px 10px;
      height: 244px;
      width: calc(100% - 5px);
      overflow-x: auto;
      text-overflow: ellipsis;
      border: 1.5px solid #111;
      border-radius: 3px;

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #9978e2;
        border-radius: 2px;
      }
    }

    .content__top {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .content__bottom {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 20px;

      .column {
        width: 50%;
      }
    }

    .content__item {
      display: flex;
      align-items: center;
      margin: 10px 0;
      column-gap: 20px;
      width: 100%;

      .active-select {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        &-item {
          cursor: pointer;
          padding: 10px 20px;
          border-bottom: 2px solid #b0b9c4;

          p {
            border-bottom: 2px solid transparent;
            color: #333f4e;
            opacity: 0.8;
            letter-spacing: 0.5px;
          }
        }

        &-item:hover {
          background-color: #eee;
        }

        &-item-active {
          cursor: pointer;
          padding: 10px 20px;
          border-bottom: 4px solid #9978e2;

          p {
            color: #9978e2;
            font-weight: 700;
            letter-spacing: 0.5px;
          }
        }
      }

      label {
        letter-spacing: 0.8px;
        font-size: 18px;
        color: #6f7486;
        opacity: 0.8;
        margin-right: 5px;
        cursor: pointer;
        min-width: 100px !important;
      }

      label.error {
        color: #c73f3f;
      }

      input {
        margin-left: auto;
        padding: 5px;
        font-size: 18px;
        letter-spacing: 0.5px;
        width: 50%;
        margin-right: 5px;
        font-weight: 600;
      }
      input::placeholder {
        font-weight: normal;
        color: rgb(194, 194, 194);
      }

      input.small {
        width: 70px;
      }

      input.error {
        border: 2px solid #c73f3f !important;
      }

      input.max-content {
        width: 100% !important;
      }

      input:active,
      input:focus,
      textarea:focus,
      textarea:active {
        border: 2px solid #9978e2;
      }

      select {
        margin-left: auto;
        padding: 5px;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }

      & textarea {
        margin-left: auto;
        padding: 5px;
        font-size: 20px;
        letter-spacing: 0.5px;
        width: 50%;
        margin-right: 5px;
        height: 100px;
        font-weight: 600;
        resize: vertical;
      }

      @media screen and (max-width: 1200px) {
        label,
        input {
          font-size: 16px;
        }
      }
    }

    .example {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        font-size: 20px;

        @media screen and (max-width: 1200px) {
          font-size: 16px;
        }
      }

      img {
        max-width: 80%;
        max-height: 400px;
      }
    }

    .bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 20px;
      padding-right: 20px;

      .error {
        text-align: right;
        margin-right: 10px;
        color: #c73f3f;
        font-size: 14px;
      }

      .buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .confirm {
          padding: 8px 15px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          background-color: #9978e2;
          transition: background-color 0.2s ease;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          border-radius: 3px;
          margin: 20px 0;
          margin-right: 30px;

          img {
            margin-right: 10px;
          }
        }

        .confirm:hover {
          background-color: #ae99e8;
        }

        .disabled {
          background-color: #aaa !important;
          cursor: not-allowed;
          color: #fff !important;
          margin-right: 30px;

          img {
            margin-right: 10px;
          }
        }

        .disabled:hover {
          background-color: #aaa !important;
        }

        .cancel {
          background-color: transparent;
          border: 1px solid #9978e2;
          color: #9978e2;
        }
      }
    }

    button {
      padding: 8px 15px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: #9978e2;
      transition: background-color 0.2s ease;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      border-radius: 3px;
      margin: 20px 0;
    }
    button:hover {
      background-color: #ae99e8;
    }

    .disabled {
      background-color: #aaa !important;
      cursor: not-allowed;
      color: #fff !important;
    }

    .disabled:hover {
      background-color: #aaa !important;
    }
  }
}
