.InstructionPage {
  margin-left: 30%;
  font-family: Arial, serif;
  padding: 30px 30px 30px 90px;
  box-sizing: border-box;
  width: 100%;
  @media screen and (max-width: 800px) {
    padding: 30px;
  }

  .InstructionPage_Title {
    font-weight: 500;
    font-size: 1.8rem;
    //text-align: center;
    display: flex;
  }

  .PersonalData {
    ul {
      margin-top: 10px;

      li {
        list-style-position: inside;
      }
    }
  }

  .InstructionPage_pBox {
    margin-top: 20px;
  }

  .InstructionPage_ImageWrap {
    padding-top: 20px;

    img {
      height: auto;
      width: 280px;
    }
  }
}
