@font-face {
  font-family: "Onest";
  src:
    local("Onest"),
    url("../assets/fonts/Onest/OnestThin1602-hint.woff") format("woff"),
    url("../assets/fonts/Onest/OnestThin1602-hint.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Onest";
  src:
    local("Onest"),
    url("../assets/fonts/Onest/OnestLight1602-hint.woff") format("woff"),
    url("../assets/fonts/Onest/OnestLight1602-hint.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Onest";
  src:
    local("Onest"),
    url("../assets/fonts/Onest/OnestRegular1602-hint.woff") format("woff"),
    url("../assets/fonts/Onest/OnestRegular1602-hint.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Onest";
  src:
    local("Onest"),
    url("../assets/fonts/Onest/OnestMedium1602-hint.woff") format("woff"),
    url("../assets/fonts/Onest/OnestMedium1602-hint.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Onest";
  src:
    local("Onest"),
    url("../assets/fonts/Onest/OnestBold1602-hint.woff") format("woff"),
    url("../assets/fonts/Onest/OnestBold1602-hint.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Onest";
  src:
    local("Onest"),
    url("../assets/fonts/Onest/OnestExtraBold1602-hint.woff") format("woff"),
    url("../assets/fonts/Onest/OnestExtraBold1602-hint.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Onest";
  src:
    local("Onest"),
    url("../assets/fonts/Onest/OnestBlack1602-hint.woff") format("woff"),
    url("../assets/fonts/Onest/OnestBlack1602-hint.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Onest";
  src:
    local("Onest"),
    url("../assets/fonts/Onest/OnestThin1602-hint.woff") format("woff"),
    url("../assets/fonts/Onest/OnestThin1602-hint.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
