.modal-window-delete-notifications-modal,
.modal-window-archive-single-modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .close-div {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p {
      margin-right: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #6f7486;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
  }

  .title-block {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    svg {
      margin-right: 20px;
    }

    h2 {
      letter-spacing: 0.5px;
      font-size: 24px;
      text-transform: uppercase;
      color: #000000;
      font-weight: 400;
    }
  }

  > p {
    font-size: 16px;
    color: #333f4e;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-right: 30px;
    margin-top: 20px;
    margin-bottom: 20px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      margin-left: 20px;
      border-radius: 3px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      svg {
        margin-right: 20px;
      }

      span {
        font-family: "Roboto", sans-serif !important;
        font-weight: 500;
        font-size: 16px;
      }
    }

    button.action {
      background-color: #9978e2;
      border: 1px solid #9978e2;

      span {
        color: white;
      }
    }

    button.danger:hover {
      background-color: rgb(143, 31, 31);
    }

    button.action:hover {
      background-color: rgb(6, 97, 224);
    }

    button.cancel {
      background-color: white;
      border: 1px solid #9978e2;

      span {
        color: rgba(51, 63, 78, 1);
      }
    }

    button.cancel:hover {
      background-color: #eee;
    }
  }
}

.modal-window-notification-error-modal,
.modal-window-archive-success-modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .close-div {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p {
      margin-right: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #6f7486;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
  }

  > p {
    font-size: 16px;
    color: #333f4e;
    line-height: 20px;
    margin-bottom: 20px;
    margin-top: 50px;
  }

  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-right: 30px;
    margin-top: 20px;
    margin-bottom: 20px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      margin-left: 20px;
      border-radius: 3px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      svg {
        margin-right: 20px;
      }

      span {
        font-family: "Roboto", sans-serif !important;
        font-weight: 500;
        font-size: 16px;
      }
    }

    button.action {
      background-color: #9978e2;
      border: 1px solid #9978e2;

      span {
        color: white;
      }
    }

    button.danger {
      background-color: brown;
      border: 1px solid brown;

      span {
        color: white;
      }
    }

    button.danger:hover {
      background-color: rgb(143, 31, 31);
    }

    button.action:hover {
      background-color: rgb(6, 97, 224);
    }

    button.cancel {
      background-color: white;
      border: 1px solid #9978e2;

      span {
        color: rgba(51, 63, 78, 1);
      }
    }

    button.cancel:hover {
      background-color: #eee;
    }
  }
}
