.content-under-header {
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  margin-top: calc(116px + 20px);
}

@media screen and (max-width: 1024px) {
  .content-under-header {
    margin-top: calc(100px + 20px);
  }
}
