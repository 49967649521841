@import "../../../../../styles/variables";

.PosterStoreWrap {
  .PosterStoreForm {
    width: 430px;
    margin: 0 auto 40px auto;
    position: relative;

    .PosterStoreForm_EditCheckbox {
      position: absolute;
      left: -30px;
      top: 10px;
    }
  }

  .ButtonConfirmWrap {
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
    height: 60px;

    button {
      transition: ease-in-out 0.1s;
      width: 100%;
      height: 40px;
      background-color: $main_bg-purple;
      border-radius: 40px;
      color: #fff;
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 0.2px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: $main_bg-purple-darkest-hover;
      }

      &:active {
        background-color: $main_bg-purple;
      }
    }
  }
}
