@import "src/styles/variables.scss";

// RHFInput
.RHFInput {
  margin-bottom: 24px;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;

  .InputWrap {
    display: flex;
    width: 100%;

    .Input {
      margin-left: auto;
      padding: 5px;
      font-size: 16px;
      letter-spacing: 0.5px;
      width: 240px;
      min-width: 240px;
      box-sizing: border-box;

      &:focus {
        border: 1px solid rgba(53, 109, 107, 0.6);
      }

      &::placeholder {
        font-size: 1.2rem;
        color: #272727;
      }
    }

    .InputDescription {
      letter-spacing: 0.8px;
      font-size: 14px;
      color: rgb(117, 117, 117);
      margin-right: 5px;
      //cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  .InputError {
    margin-top: 4px;
    font-size: 0.7rem;
    color: rgba(255, 0, 0, 1);
    display: flex;
    justify-content: flex-end;
  }
}

// RHFTextarea
.RHFTextarea {
  margin-bottom: 24px;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;

  .TextareaWrap {
    display: flex;
    width: 100%;

    .Textarea {
      margin-left: auto;
      padding: 5px;
      font-size: 16px;
      letter-spacing: 0.5px;
      width: 240px;
      min-width: 240px;
      box-sizing: border-box;
      height: 140px;
      resize: none;

      &:focus {
        border: 1px solid rgba(53, 109, 107, 0.6);
      }

      &::placeholder {
        font-size: 1.2rem;
        color: #272727;
      }
    }

    .TextareaDescription {
      letter-spacing: 0.8px;
      font-size: 14px;
      color: rgb(117, 117, 117);
      margin-right: 5px;
      //cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  .TextareaError {
    margin-top: 4px;
    font-size: 0.7rem;
    color: rgba(255, 0, 0, 1);
    display: flex;
    justify-content: flex-end;
  }
}

// RHFSelect
.RHFSelect {
  margin-bottom: 21px;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;

  .SelectWrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .SelectBox {
      height: 42px;
      box-sizing: border-box;
      user-select: none;
      cursor: pointer;

      .Select {
        font-weight: 600;
        font-size: 0.88rem;
        height: 42px;
        transition: hover 0.15s ease-in-out;
        border: 1px solid rgba(112, 120, 127, 0.5);
        width: 240px;
        background-color: #f7f9fc;
        display: flex;
        color: $color_mediumBlack;
        box-sizing: border-box;
        position: relative;
        padding-top: 0px;

        &:hover,
        &:focus,
        &:focus-within,
        &:focus-visible,
        &:active {
          outline: none;
        }

        .SelectLeft {
          width: 95%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 9px;
        }

        .SelectRight {
          width: 55px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 1px;

          &:hover {
            filter: brightness(50%);
          }

          &:active {
            filter: none;
          }
        }
      }

      .SelectOption {
        z-index: 5;
        font-weight: 600;
        font-size: 0.84rem;
        background-color: #fff;
        border-left: 1px solid rgba(112, 120, 127, 0.5);
        border-right: 1px solid rgba(112, 120, 127, 0.5);
        position: relative;
        top: -1px;
        padding: 10px 4px 8px 9px;
        box-sizing: border-box;
        width: calc(100%);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: all 0.15s ease-in-out;

        &:hover {
          background-color: rgb(237, 239, 245);
        }
      }
    }

    .SelectDescription {
      letter-spacing: 0.8px;
      font-size: 14px;
      color: rgb(117, 117, 117);
      margin-right: 5px;
      //cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  .SelectError {
    margin-top: 4px;
    font-size: 0.7rem;
    color: rgba(255, 0, 0, 1);
    display: flex;
    justify-content: flex-end;
  }
}

// EditPage_SeveralSelect
.EditPage_SeveralSelect {
  width: 80%;
  height: 68px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: hover 0.15s ease-in-out;
  @media (max-width: 920px) {
    margin: 0 auto 22px auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 670px) {
      width: 100%;
    }
  }

  .SeveralSelectWrap {
    border-radius: 12px;
    border: 1px solid rgb(226, 228, 229);
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    color: rgb(17, 30, 41);
    box-sizing: border-box;
    position: relative;
    padding-top: 4px;

    //!
    .SeveralSelectDescription {
      position: absolute;
      top: -6px;
      left: 12px;
      color: rgb(112, 120, 127);
      box-sizing: border-box;
      padding: 0 5px;
      line-height: 0.6;
      background-color: rgb(248, 248, 248);
      font-weight: 400;
      border-radius: 20px;
      font-size: 0.9rem;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      //border-top: 1px solid rgb(226,228,229);
    }

    .SeveralSelectLeft {
      //background-color: rgba(232, 232, 232, 0.3);
      border-radius: 10px;
      width: 100%;
      height: 84%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 10px;
      padding-left: 6px;
      padding-top: 8px;
      margin-top: 4px;
      overflow-x: scroll;
      overflow-y: hidden;
      //flex-wrap: wrap;
      // firefox scroll
      scrollbar-color: #e2e4e5 rgba(232, 232, 232, 0.1);
      scrollbar-width: revert-layer;

      // webkit scroll
      &::-webkit-scrollbar {
        width: 0.2rem;
      }

      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 0 0.4rem #e2e4e5;
        border: solid 0.45rem transparent;
        background-color: transparent;
      }

      .SelectedOption {
        white-space: nowrap;
        background-color: rgba(212, 223, 245, 1);
        margin-right: 5px;
        border-radius: 10px;
        padding: 3px 0 3px 10px;
        font-size: 0.8rem;
        display: flex;
        justify-content: space-between;

        .SelectedOptionText {
          height: 100%;
        }

        .SelectedOptionCloseMarkWrap {
          margin-left: 5px;
          padding-right: 13px;
          height: 13px;
          width: 8px;
          border-radius: 0 50% 50% 0;
          position: relative;

          &:hover {
            .SelectedOptionCloseMark {
              filter: invert(60%);
            }
          }

          .SelectedOptionCloseMark {
            position: absolute;
            top: 4px;
            left: 2px;
            height: 54%;
            /*position: absolute;
            top: 0;
            right: 7px;
            height: 40%;*/
          }
        }
      }
    }

    .SeveralSelectRight {
      width: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding-bottom: 12px;

      &:hover {
        filter: brightness(50%);
      }

      &:active {
        filter: none;
      }
    }
  }

  .SeveralSelectOption {
    z-index: 5;
    background-color: #fff;
    border-left: 1px solid rgb(226, 228, 229);
    border-right: 1px solid rgb(226, 228, 229);
    position: relative;
    top: -8px;
    right: 0;
    padding: 10px 4px 8px 16px;
    box-sizing: border-box;
    width: calc(100%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    transition: all 0.15s ease-in-out;

    &:hover {
      background-color: rgb(237, 239, 245);
    }
  }
}

// RHFSeveralSelect
.RHFSeveralSelect {
  width: 80%;
  height: 68px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: hover 0.15s ease-in-out;
  @media (max-width: 920px) {
    margin: 0 auto 22px auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 670px) {
      width: 100%;
    }
  }

  .SeveralSelectWrap {
    border-radius: 12px;
    border: 1px solid rgb(118, 118, 118);
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    color: rgb(17, 30, 41);
    box-sizing: border-box;
    position: relative;
    padding-top: 4px;

    //!
    .SeveralSelectDescription {
      position: absolute;
      top: -6px;
      left: 12px;
      color: rgb(112, 120, 127);
      box-sizing: border-box;
      padding: 0 5px;
      line-height: 0.6;
      background-color: rgb(248, 248, 248);
      font-weight: 400;
      border-radius: 20px;
      font-size: 0.9rem;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      //border-top: 1px solid rgb(226,228,229);
    }

    .SeveralSelectLeft {
      //background-color: rgba(232, 232, 232, 0.3);
      border-radius: 10px;
      width: 100%;
      height: 84%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 10px;
      padding-left: 6px;
      padding-top: 8px;
      margin-top: 4px;
      overflow-x: scroll;
      overflow-y: hidden;
      //flex-wrap: wrap;
      // firefox scroll
      scrollbar-color: #e2e4e5 rgba(232, 232, 232, 0.1);
      scrollbar-width: revert-layer;

      // webkit scroll
      &::-webkit-scrollbar {
        width: 0.2rem;
      }

      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 0 0.4rem #e2e4e5;
        border: solid 0.45rem transparent;
        background-color: transparent;
      }

      .SelectedOption {
        white-space: nowrap;
        //background-color: rgba(212, 223, 245, 1);
        background-color: #cfc7fb;
        margin-right: 5px;
        border-radius: 10px;
        padding: 3px 0 3px 10px;
        font-size: 0.8rem;
        display: flex;
        justify-content: space-between;

        .SelectedOptionText {
          height: 100%;
        }

        .SelectedOptionCloseMarkWrap {
          margin-left: 5px;
          padding-right: 13px;
          height: 13px;
          width: 8px;
          border-radius: 0 50% 50% 0;
          position: relative;

          &:hover {
            .SelectedOptionCloseMark {
              filter: invert(60%);
            }
          }

          .SelectedOptionCloseMark {
            position: absolute;
            top: 4px;
            left: 2px;
            height: 54%;
            /*position: absolute;
            top: 0;
            right: 7px;
            height: 40%;*/
          }
        }
      }
    }

    .SeveralSelectRight {
      width: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding-bottom: 12px;

      &:hover {
        filter: brightness(50%);
      }

      &:active {
        filter: none;
      }
    }
  }

  .SeveralSelectOption {
    z-index: 5;
    background-color: #fff;
    border-left: 1px solid rgb(118, 118, 118);
    border-right: 1px solid rgb(118, 118, 118);
    position: relative;
    top: -8px;
    right: 0;
    padding: 10px 4px 8px 20px;
    box-sizing: border-box;
    width: calc(100%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    transition: all 0.15s ease-in-out;
    font-size: 1rem;

    &:hover {
      background-color: rgb(237, 239, 245);
    }
  }
}

// RHFPhoto
.RHFPhoto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  .Description {
    font-size: 14px;
    letter-spacing: 0.8px;
    color: #757575;
  }

  .ImageBox {
    width: 100%;
    max-width: 240px;

    .ImageWrap {
      width: 240px;
      height: 240px;
      border-radius: 5px;
      background-color: #c2c2c2;
      contain: content;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .FormArea {
    display: flex;
    align-items: center;
    position: relative;
  }
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    height: 100%;

    span {
      max-width: 80px;
    }
  }

  .label-for-file-p {
    font-size: 14px;
    letter-spacing: 0.8px;
    color: #757575;
    font-weight: 500;
    cursor: pointer;
    height: 30px;
    width: 100%;
    border: 1px solid rgba(17, 17, 17, 0.25);
    //border: 1px solid rgb(118, 118, 118);
    border-radius: 5px;
    padding: 2px;
    background-color: rgba(117, 117, 117, 0.05);

    &:hover {
      color: #111;
    }
  }
}
