@import "src/styles/variables.scss";

.auth {
  font-weight: 400;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 550px;
  align-items: stretch;
  position: relative;

  &_label {
    width: 30%;
    background-color: $main_bg-black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &_top {
      width: 100%;
      padding: 10px 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      &_left {
        padding-right: 20px;
        border-right: 1px solid $main_onBg-textColor;
        width: calc(30% - 30px);
        min-width: 40px;
        max-width: 70px;
      }
      div {
        width: calc(70% - 30px);
        margin-left: 20px;
        .first {
          font-size: 14px;
          color: $main_onBg-textColor;
          font-weight: 400;
        }
        .second {
          font-weight: 500;
          font-size: 22px;
          color: $main_onBg-textColor;
        }
      }
      @media screen and (max-width: 800px) {
        div {
          margin-left: 10px;
        }
      }
      @media screen and (max-width: 600px) {
        div {
          margin-left: 5px;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      &_top {
        padding: 10px 10px;
        &_left {
          padding-right: 10px;
        }
        &_right {
          margin-left: 10px;
        }
      }
    }
    &_bottom {
      width: 100%;
      display: flex;
      padding-bottom: 35px;
      flex-direction: column;
      align-items: center;
      .stripe {
        width: calc(100% - 20px);
        margin: 0 10px;
        height: 1px;
        background-color: $main_onBg-textColor;
      }
      &_top {
        width: 100%;
        padding: 0 20px;
        padding-bottom: 10px;
        .logos {
          width: 100%;
          display: flex;
          align-items: center;
          margin-right: 16px;
          .pl {
            padding-left: 8px;
            width: 50%;
            max-width: 70px;
          }
          .pr {
            padding-right: 8px;
            border-right: 2px solid $main_onBg-textColor;
            width: 50%;
            max-width: 80px;
          }
        }
      }
      &_bottom {
        width: 100%;
        padding: 0 20px;
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        & p {
          cursor: pointer;
          font-weight: 400;
          letter-spacing: 0.01em;
          color: $main_onBg-textColor;
          margin-left: 15px;
          font-size: 1.7vw;
          @media screen and (min-width: 1024px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  &_main {
    width: 70%;
    background-color: #fff;
    height: 100%;
    @media screen and (min-height: 540px) {
      height: 100vh;
    }
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &_top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      li {
        list-style-type: none;
        padding: 10px 15px;
        border-bottom: 3px solid #bdbdbd;
        color: #758085;
        font-weight: 500;
        font-size: 18px;
        cursor: pointer;
      }
      .active {
        border-bottom: 3px solid $main_bg-purple;
        color: #000;
      }
    }

    &__area {
      width: 600px;
      margin-top: 20px;
      &_header {
        width: 100%;
        padding: 10px;
        border-bottom: 6px solid $main_bg-purple;
        text-align: center;
        & p {
          font-size: 32px;
          font-weight: 4000;
          color: #000;
        }
      }

      &_button {
        transition: ease-in-out 0.1s;
        width: 100%;
        height: 79px;
        background-color: $main_bg-purple;
        border-radius: 40px;
        color: #fff;
        font-weight: 700;
        font-size: 22px;
        letter-spacing: 0.2px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        border: none;
        cursor: pointer;
        &:hover {
          background-color: $main_bg-purple-darkest-hover;
        }
      }
      &_disabled_button {
        width: 100%;
        height: 79px;
        background-color: #eee;
        color: #aaa;
        font-weight: 700;
        font-size: 22px;
        letter-spacing: 0.2px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        border: none;
        cursor: not-allowed;
        border-radius: 40px;
      }
      & .parag {
        font-weight: 400;
        font-size: 15px;
        margin-top: 30px;
        letter-spacing: 0.01em;
        color: #263238;
        &:first-letter {
          color: red !important;
        }
      }
    }
  }

  &_footer {
    width: 100%;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 20px;
    flex-wrap: nowrap;
    p {
      font-size: 12px;
      color: #6f7486;
    }
  }
}

@media screen and (max-width: 1024px) {
  .auth {
    &_label {
      & h2 {
        font-size: 20px;
      }
      & h1 {
        font-size: 35px;
      }
    }
    &_main {
      &__area {
        width: 70%;
        &_header {
          & p {
            font-size: 24px;
          }
        }
        &_button {
          height: 50px;
          font-size: 18px;
        }
      }
    }
    &_footer {
      &_left {
        &_yellow {
          display: none;
        }
      }
    }
  }
}

// instructionPage
.instructionPage_contacts {
  width: 100%;
  padding-bottom: 10px;

  .contactBox {
    font-weight: 300;
    letter-spacing: 0.01em;
    line-height: 0.88rem;
    color: #fff;
    margin-left: 15px;
    display: flex;
    margin-bottom: 25px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .contactDescription {
      margin-bottom: 4px;
      font-size: 0.72rem;
      @media screen and (max-width: 670px) {
        font-size: 0.62rem;
      }
    }
    .contactValue {
      font-size: 0.82rem;
      text-decoration: none;
      color: #fff;
      font-weight: 400;
      @media screen and (max-width: 670px) {
        font-size: 0.62rem;
      }
    }
  }
}
