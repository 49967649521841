.administration-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .not-selected-shop {
    width: calc(100% - 30px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    border: 1px solid #5b5d65a2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;

    .centered-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    span {
      color: #5b5d65;
      letter-spacing: 1px;
      font-size: 20px;
      opacity: 0.8;
    }
  }
}

.administration-loading-container {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
