@import "src/styles/variables.scss";

.ExclusionDays {
  padding: 8px 0 20px 0;
  box-sizing: border-box;

  h4 {
    font-weight: 400;
    color: #757575;
    font-size: 14px;
    margin-bottom: 4px;
    margin-left: 1px;
  }

  .ExclusionDaysArea {
    border: 1px solid rgba(17, 17, 17, 0.4);
    border-radius: 5px;
    width: 100%;
    height: 148px;
    padding: 14px 6px;
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
    scrollbar-color: rgba(17, 17, 17, 0.25) rgba(232, 232, 232, 1);
    scrollbar-width: revert-layer;
    position: relative;
    z-index: 1;
    overflow-y: auto;

    &::-webkit-scrollbar {
      position: absolute;
      width: 0.941rem;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 0 0.1rem rgba(105, 114, 114, 0.35);
      border: solid 0.42rem transparent;
      background-color: transparent;
    }

    /*   &::-webkit-scrollbar-thumb {
         background-color: #257DF7;
         border-radius: 2px;
       }*/

    .ExclusionDayWrap {
      position: relative;
      height: 32px;
      display: flex;
      margin-bottom: 12px;

      .ExclusionDay {
        transition: all ease-in-out 0.16s;
        position: relative;
        //background-color: rgba(153, 120, 226, 1);
        background-color: rgba(153, 120, 226, 0.9);
        //background-color: $main_bg-orange;
        border-radius: 5px;
        margin: 0 4px 0 4px;
        width: 110px;
        height: 100%;
        color: #fff;
        font-weight: 600;
        border: 1px solid rgba(17, 17, 17, 0.4);
        font-size: 0.9rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 7px 0 0;
        cursor: pointer;

        .ExclusionDay_DateBox {
          font-size: 0.9rem;
        }

        .ExclusionDay_TimeBox {
          font-size: 0.6rem;
        }

        .ExclusionDay_Close {
          transition: all ease-in-out 0.16s;
          position: absolute;
          top: -2px;
          right: -2px;
          width: 14px;
          height: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          border-radius: 50%;
          border: 1px solid rgba(17, 17, 17, 0.4);

          img {
            transition: all ease-in-out 0.16s;
            height: 6px;
            width: 6px;
            opacity: 0.6;
          }

          &:hover {
            border: 1px solid rgba(17, 17, 17, 0.6);
          }
        }

        &:hover {
          //border: 1px solid rgba(17, 17, 17, 0.8);
          background-color: rgba(153, 120, 226, 0.8);

          img {
            opacity: 1;
          }
        }
      }

      .ExclusionDayWrap_Comma {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        top: 4px;
        font-size: 1.2rem;
      }
    }
  }

  .ExclusionDays_AddingNew {
    width: 100%;
    padding-top: 24px;

    .ExclusionDays_AddingNewFirst {
      display: flex;
      align-items: center;

      > div {
        margin-right: 14px;
      }
    }

    .ExclusionDays_AddingNewTimeBox {
      margin-top: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 160px;
      height: 35px;

      span {
        height: 19px;
      }
    }
  }
}

.AddingNewFormRadio {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  width: 160px;
  height: 56px;
  margin-top: 12px;
  padding: 8px 10px;
  border: 1px solid rgba(226, 228, 229, 0.55);
  border-radius: 4px;
  box-sizing: border-box;

  div {
    height: 16px;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    display: inline-block;
    color: #666;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
    opacity: 0.8;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: $main_bg-purple_darkest;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  [type="radio"]:checked + label:before {
    border: 1px solid $main_bg-purple_darkest;
  }
  [type="radio"]:checked + label {
    font-weight: 600;
    color: #000;
  }
}

// common
.AddingNewTime_AddingButton {
  padding: 5px 10px;
  border: none;
  background-color: $main_bg-purple_darkest;
  color: #fff;
  cursor: pointer;
  width: 160px;
  margin-top: 10px;
  border-radius: 4px;

  &:hover {
    background-color: $main_bg-purple_darkest-hover;
  }
  &:active {
    opacity: 1;
  }
}
