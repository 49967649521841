.main-page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .selecting-market-point-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: flex-start;
  }

  .not-selected-shop {
    width: calc(100% - 30px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    border: 1px solid #5b5d65a2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;

    .centered-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    span {
      color: #5b5d65;
      letter-spacing: 1px;
      font-size: 20px;
      opacity: 0.8;
    }
  }
}

.main-page-loading-container {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
