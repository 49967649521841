.Alert {
  z-index: 999;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(100, 149, 237, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  .AlertBox {
    width: 500px;
    box-sizing: border-box;
    padding: 48px 30px 48px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    position: relative;
    border-radius: 12px;

    .AlertCloseIcon {
      position: absolute;
      top: 0;
      right: 0;
      padding: 16px;
      cursor: pointer;

      &:hover {
        opacity: 0.55;
      }
    }

    .AlertConfirmIcon {
      margin-bottom: 30px;
      width: 140px;
      height: 140px;
    }

    .AlertTitle {
      margin: 0;
      font-size: 24px;
      font-weight: 600;
      white-space: nowrap;
    }

    .AlertDescription {
      color: rgb(112, 120, 127);
      font-size: 1.1rem;
      font-weight: 400;
      margin-top: 14px;
      margin-bottom: 4px;
    }

    .AlertTextFieldBox {
      margin-top: 30px;
      position: relative;
      min-width: 342px;
      width: 100%;
      height: 110px;

      .AlertTextFieldDescription {
        position: absolute;
        top: -12px;
        left: 12px;
        color: rgb(112, 120, 127);
        padding: 3px 5px;
        background-color: #fff;
        font-size: 0.87rem;
        font-weight: 400;
      }

      .AlertTextarea {
        font-size: 1.2rem;
        color: rgba(17, 30, 41, 1);
        width: 100%;
        height: 100%;
        resize: none;
        outline: none;
        border-radius: 12px;
        background-color: #fff;
        border: 1px solid rgb(226, 228, 229);
        box-sizing: border-box;
        padding: 11px 12px 10px 17px;
        overflow-x: hidden;
        overflow-y: auto;
        // firefox scroll
        scrollbar-color: #e5e5e5 #fff;
        scrollbar-width: thin;
        transition: all 0.1s ease-in-out;

        &:focus {
          border: 1px solid rgba(53, 109, 107, 0.6);
        }

        // webkit scroll
        &::-webkit-scrollbar {
          width: 0.12rem;
        }

        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 0 0.4rem #e5e5e5;
          border: solid 0.2rem transparent;
          background-color: transparent;
        }
      }

      .AlertInput {
        width: 100%;
        height: 48px;
        border-radius: 12px;
        background-color: #fff;
        color: rgba(17, 30, 41, 1);
        border: 1px solid rgb(226, 228, 229);
        padding: 16px 18px;
        font-size: 1.2rem;
        box-sizing: border-box;
      }
    }
  }

  .ChoosingAlertBox {
    width: 500px;
    box-sizing: border-box;
    padding: 64px 30px 18px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    position: relative;
    border-radius: 12px;

    .AlertCloseIcon {
      position: absolute;
      top: 0;
      right: 0;
      padding: 16px;
      cursor: pointer;

      &:hover {
        opacity: 0.55;
      }
    }

    .AlertConfirmIcon {
      margin-bottom: 30px;
      width: 140px;
      height: 140px;
    }

    .AlertTitle {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      //white-space: nowrap;
    }

    .AlertDescription {
      color: rgb(112, 120, 127);
      font-size: 1.1rem;
      font-weight: 400;
      margin-top: 14px;
      margin-bottom: 4px;
    }

    .AlertTextFieldBox {
      margin-top: 30px;
      position: relative;
      min-width: 342px;
      width: 100%;
      height: 110px;

      .AlertTextFieldDescription {
        position: absolute;
        top: -12px;
        left: 12px;
        color: rgb(112, 120, 127);
        padding: 3px 5px;
        background-color: #fff;
        font-size: 0.87rem;
        font-weight: 400;
      }
    }
  }

  .AlertLogsBox {
    max-height: 54vh;
    width: 100%;
    margin-top: 18px;
    padding: 10px 0 10px 12px;
    border-radius: 12px;
    margin-left: 2px;
    box-sizing: border-box;
    overflow: auto;
    scrollbar-color: rgba(17, 17, 17, 0.25) rgba(22, 139, 134, 0.25);
    scrollbar-width: revert-layer;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
      position: absolute;
      width: 0.85rem;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 0 0.4rem rgba(22, 139, 134, 0.25);
      border: solid 0.34rem transparent;
      background-color: transparent;
    }

    .LogUnitWrap {
      font-size: 0.94rem;
      width: 100%;
      //border-bottom: 1px solid rgba(17, 17, 17, 0.1);
      padding-right: 18px;
      margin-bottom: 20px;

      .LogUnitHeader {
        color: inherit;
        font-weight: 600;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 6px 8px 2px;
        margin-bottom: 6px;
        border-bottom: 1px solid rgba(22, 139, 134, 0.3);

        .LogUnitName {
          color: #168b86;
          font-size: 1.1rem;
        }

        .LogUnitHeaderRight {
          color: #168b86;
          .LogUnitDate {
            font-size: 0.75rem;
            text-align: center;
            margin-top: 3px;
          }
          .LogUnitType {
            font-size: 0.9rem;
          }
        }
      }

      .LogUnitInfo {
        box-sizing: border-box;
        padding: 12px 4px 0 4px;
        font-weight: 600;

        .LogUnitInfoText {
          margin-bottom: 12px;
        }
      }

      .LogUnitErrors {
        box-sizing: border-box;
        padding: 20px 4px 10px 4px;
        font-weight: 600;
        //border-top: 1px dashed $color_red;

        .LogUnitErrorText {
          color: rgb(221, 60, 60);
          font-weight: 600;
          margin-bottom: 12px;
        }
      }

      .LogUnitWarnings {
        box-sizing: border-box;
        padding: 16px 4px 10px 4px;
        //border-top: 1px dashed $color_red;

        .LogUnitWarningText {
          color: rgb(221, 60, 60);
          margin-bottom: 12px;
        }
      }
    }
  }

  .AlertText {
    margin-top: 14px;
    line-height: 1.2;
    padding: 0 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: rgb(112, 120, 127);
  }

  .ButtonsWrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 55px;
    margin-top: 24px;

    .ButtonCommon,
    .ButtonDisprove,
    .ButtonConfirm {
      width: 47%;
      height: 100%;
      text-transform: uppercase;
      font-size: 1.1rem;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: 400;
      transition: all 0.1s ease-in-out;
    }

    .ButtonDisprove {
      color: rgba(17, 30, 41, 1);
      border: 1px solid #e2e4e5;

      &:hover {
        background-color: rgba(241, 241, 241, 0.15);
        filter: contrast(80%);
        border: 1px solid rgba(226, 228, 229, 0.5);
      }

      &:active {
        background-color: #fff;
        filter: none;
      }
    }

    .ButtonCommon,
    .ButtonConfirm {
      background: linear-gradient(76.12deg, #8260c4 -175.56%, #9978e2 177.37%);
      border: 1px solid rgba(53, 109, 107, 0.6);
      color: #fff;

      &:hover {
        background: linear-gradient(
          76.12deg,
          #ae99e8 -175.56%,
          #9978e2 177.37%
        );
      }

      &:active {
        background: linear-gradient(
          76.12deg,
          #8260c4 -175.56%,
          #9978e2 177.37%
        );
      }
    }

    .ButtonCommon {
      height: 40px;
      font-size: 1.1rem;
    }
  }
}
