.flex-column {
  .bell {
    margin-right: 10px;
    width: 43px;
    height: 43px;
    position: relative;
    cursor: pointer;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .bell-icon {
    transition: fill 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .bell:hover {
    background-color: rgba(25, 118, 210, 0.2);
  }

  .bell:hover .bell-icon {
    fill: blue;
  }

  .bell-indicator {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid #fff;
    transition: background-color 0.3s ease;
    position: absolute;
    top: 2px;
    right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: #fff;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .bell-indicator.red {
    background-color: rgba(226, 112, 7, 1);
  }

  .bell-indicator.green {
    background-color: rgba(89, 163, 56, 1);
  }
}

.new-message-modal-window {
  width: 450px;
  cursor: pointer;
  background-color: rgba(47, 128, 237, 1);
  box-shadow: 0px 1px 4px 1px rgba(69, 77, 88, 0.3);
  border: 1px dashed #2d9cdb;
  border-radius: 2px;
  position: absolute;
  top: 20px;
  padding: 5px 15px;
  right: calc(50% - 215px);
  display: flex;
  flex-direction: row;
  align-items: center;
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;

  .message-title {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    span {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      letter-spacing: 0.2;
      color: white;
      font-weight: 400;
    }
  }

  .buttons {
    margin-left: auto;
    display: flex;
    align-items: center;

    button {
      border: none;
      background-color: transparent;
      color: white;
      transition: background-color 0.3s ease;
      border-radius: 3px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button:hover {
      background-color: rgb(36, 114, 216);
      cursor: pointer;
    }

    button:last-child {
      margin-left: 10px;
    }

    button.look {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-size: 16px;
    }

    button.close {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.new-message-modal-window.opened {
  transform: translateY(0);
  opacity: 1;
}

.new-message-modal-window.closed {
  transform: translateY(-100%);
  opacity: 0;
}
