.auth-modal-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #c4c4c4;
  opacity: 0.7;
  z-index: 100;
}

.auth-modal-block-content {
  z-index: 101;
  max-height: 85%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #9978e2;
    border-radius: 2px;
  }
  position: fixed;
  width: 50%;
  top: 10%;
  left: 25%;
  margin-right: -50%;
  padding: 15px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  padding-bottom: 150px;

  .close-area {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 20px;
    margin-top: 20px;
    cursor: pointer;
    & p {
      font-weight: 400;
      font-size: 16px;
      color: #425165;
      margin-right: 8px;
    }
  }
  &_body {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .parag {
      font-weight: 400;
      color: #333333;
      padding-bottom: 15px;
      margin-top: 25px;
      font-size: 24px;
      border-bottom: 2px solid #bdbdbd;
    }
    &_list {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      & div {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        & div {
          margin-left: 30px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          p {
            letter-spacing: 0.01em;
            color: #425165;
            opacity: 0.8;
            font-size: 18px;
          }
          a {
            margin-top: 20px;
            text-decoration: none;
            color: #333333;
            font-size: 18px;
            cursor: pointer;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.auth-modal-block-content.create-prod-modal {
  width: 80% !important;
  left: 10%;
}

@media screen and (max-width: 1000px) {
  .auth-modal-block-content {
    width: 430px;
    left: calc(50% - 215px);
  }
}

.auth-modal-anim-enter {
  opacity: 0;
  transform: scale(0.9);
}

.auth-modal-anim-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition:
    opacity 300ms,
    transform 300ms;
}

.auth-modal-anim-exit {
  opacity: 1;
}

.auth-modal-anim-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition:
    opacity 300ms,
    transform 300ms;
}

@media screen and (max-width: 1000px) {
  .products-modal-block-content {
    width: 450px;
    left: calc(50% - 225px);
  }
}
