.disabled_phone_block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & div {
    width: 80%;
    margin-top: 30px;
    padding: 10px;
    border-radius: 5px;
    background-color: #e8edf3;
    border: 1px solid black;
    p {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      line-height: 1.5;
    }
  }
}

// common animations
.Animation_Opacity_06to1_01s {
  animation: 0.1s opacity-06_1 linear;
  opacity: 0.6;
  animation-fill-mode: forwards;
}
.Animation_Opacity_0to1_03s {
  animation: 0.3s opacity-0_1 linear;
  opacity: 0;
  animation-fill-mode: forwards;
}

.Animation_Opacity_0to1_04s {
  animation: 0.4s opacity-0_1 linear;
  opacity: 0;
  animation-fill-mode: forwards;
}
.Animation_Opacity_0to1_08s {
  animation: 0.8s opacity-0_1 linear;
  opacity: 0;
  animation-fill-mode: forwards;
}
.Animation_Opacity_1to0_04s {
  animation: 0.4s opacity-1_0 linear;
  opacity: 1;
  animation-fill-mode: forwards;
}
.Animation_Opacity_1to0_08s {
  animation: 0.8s opacity-1_0 linear;
  opacity: 1;
  animation-fill-mode: forwards;
}

@keyframes opacity-0_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes opacity-1_0 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes opacity-06_1 {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 1;
  }
}
