.footer {
  width: 100%;
  background-color: #ededed;
  font-size: 12px;
  color: #73788c;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  bottom: 0;
  &_logos {
    display: flex;
    align-items: center;
    margin-right: 16px;
    .pl {
      padding-left: 8px;
    }
    .pr {
      padding-right: 8px;
      border-right: 2px solid #aaa;
    }
  }
  & p {
    margin-right: 20px;
  }
}
