.main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 30px;
  width: 100%;

  &_half {
    width: calc(50% - 20px);
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    &:first-child {
      flex: 1 1 auto;
    }
  }

  &_places {
    width: 20%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid #73788c;
    &:first-child {
      flex: 1 1 auto;
    }

    &__header {
      background-color: #fff;
      width: 100%;
      max-height: 230px;
      padding: 10px;
      border-bottom: 1px solid #73788c;

      &_title {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        width: 100%;

        & img {
          margin-right: 10px;
        }

        & h2 {
          text-transform: uppercase;
          font-weight: 400;
          font-size: 20px;
        }
      }

      &_subtitle {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #73788c;
        font-size: 14px;
        font-weight: 400;

        .search {
          width: 100%;
          align-self: center;
          background-color: rgba(237, 237, 237, 0.6);
          border-radius: 4px;
          padding: 7px 5px;
          display: flex;
          align-items: center;
          cursor: pointer;

          label {
            cursor: pointer;
          }

          img {
            margin-right: 10px;
          }

          input {
            width: 100%;
            text-overflow: ellipsis;
            border: 0px;
            background-color: transparent;
            font-family: "Roboto", sans-serif;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.5px;
            color: #5b5d65;
          }

          input::placeholder {
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  &_orders {
    width: 37%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    &:first-child {
      flex: 1 1 auto;
    }
  }

  &_menu {
    width: 40%;
    display: flex;
    flex-direction: column;
    &:first-child {
      flex: 1 1 auto;
    }
  }
}

@media screen and (max-width: 1024px) {
  .main {
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    &_places {
      width: 90%;
      margin: 0;
      margin-bottom: 20px;
    }
    &_orders {
      width: 90%;
      margin: 0;
      margin-bottom: 20px;
    }
    &_menu {
      width: 90%;
    }
    &_half {
      width: 90%;
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}

.main-page-selected-merch {
  z-index: 0;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  label {
    img.shop_img {
      margin-right: 8px;
      width: 15px;
    }

    color: rgb(46, 43, 43);
    font-size: 18px;
    margin-right: 10px;
  }

  label.warning-message {
    font-size: 15px;
    color: rgb(189, 128, 15);
  }

  select {
    padding: 5px 10px;
    width: 200px;
    cursor: pointer;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    background-color: #bde139;
    border-radius: 3px;
  }

  .cancel-icon {
    color: #000;
    cursor: pointer;
    margin-left: 10px;
  }
}
