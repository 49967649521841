.order-item-container {
  width: 100%;
  position: relative;
  border-bottom: 1px solid rgba(91, 93, 101, 0.4);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;

  @media screen and (max-width: 1200px) {
    padding: 15px 5px;
  }

  cursor: pointer;

  .main-selected-order-user {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    max-width: 100px;
    padding: 3px 10px;
    font-size: 12px;
    border-radius: 3px;
    border: 1px solid rgb(202, 202, 202);
    margin-left: auto;
  }

  .main-selected-order-user-active {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    max-width: 100px;
    padding: 3px 10px;
    font-size: 12px;
    font-weight: 600;
    color: #9978e2;
    border-radius: 3px;
    border: 1px solid #9978e2;
    margin-left: auto;
  }

  @media screen and (min-width: 1600px) {
    .main-selected-order-user,
    .main-selected-order-user-active {
      /*font-size: 16px;*/
      max-width: 150px;
    }
  }

  .order-item-container-top {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .order-label {
      color: #73788c;
      font-weight: 600;
      letter-spacing: 0.2px;
      cursor: pointer;
      text-decoration: none;
      color: #4f5053;
      font-size: 18px;
    }

    .order-created-time {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 5px;
      }

      span {
        letter-spacing: 0.2px;
        color: rgb(143, 145, 156);
        font-weight: 600;
        font-size: 16px;
      }
    }

    .total-price {
      color: #333f4e;
      font-size: 18px;
      font-weight: 600;
      margin-left: auto;
    }
  }

  .order-item-container-middle {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 8px 0;

    .client-info {
      display: flex;
      flex-direction: row;
      align-items: center;

      .name {
        font-size: 16px;
        letter-spacing: 0.2;
        color: rgba(141, 144, 156, 0.8);
        font-weight: 600;
      }

      .phone {
        color: #9978e2;
        letter-spacing: 0.2px;
        font-size: 16px;
        font-weight: 600;
        margin-left: 10px;
      }

      .comment {
        margin-left: 10px;
      }
    }
  }

  .order-item-container-bottom {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .order-item_status {
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 20px;

      & span {
        margin-left: 5px;
      }
    }

    .desirable_delivery_time {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;

      img {
        margin-right: 5px;
      }

      span {
        color: #5b5d65;
        opacity: 0.8;
        letter-spacing: 0.2px;
        font-size: 16px;
      }
    }

    .order_take {
      border: 1px solid #59a338;
      border-radius: 4px;
      color: #59a338;
      font-size: 14px;
      font-weight: 700;
      margin-right: 30px;
      padding: 5px 5px;
      text-align: center;
      cursor: pointer;
      z-index: 1;
      transition:
        border,
        color,
        box-shadow 0.2s ease;
      margin-left: 10px;

      &:hover {
        border: 1px solid #1e410e;
        box-shadow: 1px 0px 13px 0.5px rgb(214, 214, 214);
        color: #1e410e;
      }
    }

    // error
    .status_-1 {
      background-color: #fbe2e2;
      color: #ad3d3d;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.2px;
    }

    .status_0 {
      background-color: #fff;
      color: #73788c;
      font-weight: 600;
      font-size: 14px;
      border: 1px solid #73788c;
      letter-spacing: 0.2px;
    }

    .status_1 {
      background-color: #fbe2e2;
      color: #ad3d3d;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.2px;
    }

    .status_2 {
      background-color: #59a338;
      color: #fff;
      font-weight: 700;
      font-size: 14px;
    }

    .status_3 {
      background-color: #bdd4b2;
      color: #3d7e1f;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
    }

    .status_4 {
      background-color: #f3eea8;
      color: #c99639;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
    }

    .status_5 {
      background-color: #bdd4b2;
      color: #3d7e1f;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
    }

    .status_6 {
      background-color: #dbeed3;
      color: #306915;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
    }

    .status_7 {
      background-color: #fbe2e2;
      color: #ad3d3d;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.2px;
    }
  }

  &_right {
    display: flex;
    flex-direction: row;
    align-items: center;

    &_take {
      border: 1px solid #59a338;
      border-radius: 4px;
      color: #59a338;
      font-size: 14px;
      font-weight: 700;
      margin-right: 30px;
      padding: 5px;
      width: 70px;
      text-align: center;
      cursor: pointer;
      z-index: 1;
      transition:
        border,
        color,
        box-shadow 0.2s ease;
    }

    &_take:hover {
      border: 2px solid #1e410e;
      box-shadow: 1px 0px 13px 0.5px rgb(214, 214, 214);
      color: #1e410e;
    }

    &_price {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      text-align: right;

      & h3 {
        color: #333f4e;
        font-size: 18px;
        font-weight: 600;
      }

      & p {
        color: #898fa5;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.2px;
        margin-top: 15px;
      }
    }
  }
}
