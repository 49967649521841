@import "src/styles/variables.scss";

.storage {
  width: 100%;
  min-height: 300px;
  position: relative;

  .storage-links {
    display: flex;
    align-items: center;
    margin: 20px;

    a {
      margin-right: 20px;
      background-color: #f0f0f0;
      padding: 10px 20px;
      border-radius: 3px;
      color: black;
      text-decoration: none;
      text-transform: uppercase;
      border-bottom: 2.5px solid transparent;
    }

    .active {
      background-color: $main_bg-purple_transparent;
      border-bottom: 2.5px solid #ae99e8;
    }
  }
}

.products-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;

  .close-div {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p {
      margin-right: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #6f7486;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
  }

  &_header {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 60px;
    padding-top: 20px;

    h2 {
      text-transform: uppercase;
      font-size: 20px;
    }

    button {
      margin-right: 20px;
      padding: 8px 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: #9978e2;
      transition: background-color 0.2s ease;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      border-radius: 3px;

      svg {
        margin-right: 5px;
      }
    }

    button:hover {
      background-color: #ae99e8;
    }
  }

  &_header_bottom {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 60px;
    margin-top: 20px;

    button {
      margin-top: 10px;
      padding: 18px 20px;
      font-size: 16px;
      color: #fff;
      background-color: #9978e2;
      border: none;
      border-radius: 2px;
      cursor: pointer;
    }

    button:hover {
      background-color: #ae99e8;
    }

    .cancel {
      padding: 18px 20px;
      margin-top: 10px;
      font-size: 16px;
      color: #fff;
      background-color: brown;
      border: none;
      border-radius: 2px;
      cursor: pointer;
    }

    .cancel:hover {
      background-color: rgb(131, 32, 32);
    }
  }

  &_content {
    margin-top: 10px;
    width: calc(100% - 40px);

    @media screen and (max-width: 900px) {
      width: 100%;
    }

    border: 1px solid #c6c7cf;
    border-bottom: none;
    list-style-type: none;

    &-header {
      padding: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #c6c7cf;

      .left {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin-right: 10px;
        }

        p {
          text-transform: uppercase;
          font-size: 20px;
          color: #000;
        }
      }

      button {
        border: 1px solid #c6c7cf;
        border-radius: 2px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        cursor: pointer;
        transition: background-color 0.2s ease;

        img {
          margin-right: 7px;
        }

        p {
          font-size: 16px;
        }
      }

      button:hover {
        background-color: #eee;
      }
    }

    &-header-names {
      width: 100% !important;
      border-bottom: none !important;
      border-bottom: 1px solid #c6c7cf !important;

      .pd-name,
      .pd-image,
      .pd-cat,
      .pd-price,
      .pd-count,
      .cat-name,
      .cat-image,
      .cat-list,
      .cat-tools {
        color: #6f7486 !important;
        opacity: 0.8 !important;
        font-size: 14px !important;
        font-weight: normal !important;
        align-items: center !important;
      }

      .pd-name,
      .pd-cat,
      .pd-count {
        justify-content: flex-start !important;
        padding-left: 10px !important;
      }

      .pd-price {
        justify-content: flex-end !important;
        padding-right: 10px !important;
      }
    }

    li {
      width: 100%;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #c6c7cf;

      .cat-name {
        width: calc(50% - 125px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #c6c7cf;
        padding: 5px 0;
        text-align: center;

        a {
          text-decoration: none;
          width: 100%;
          color: #000;
          margin: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        a:hover {
          color: #9978e2;
        }
      }

      .cat-image {
        width: calc(50% - 125px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #c6c7cf;
        padding: 10px;

        img {
          max-width: 80%;
          max-height: 200px;
        }
      }

      .cat-list {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #c6c7cf;

        ul {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          a {
            text-decoration: none;
            width: 100%;
            color: #000;
            margin: 10px 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          a:hover {
            color: #9978e2;
          }
        }
      }

      .cat-tools {
        width: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 10px;
        padding: 20px 0;

        .active-selector {
          padding: 10px;
          border-radius: 50%;
          transition: background-color 0.3s ease;
          cursor: pointer;
          &:hover {
            background-color: #bad5fa;
          }
        }

        .red-selector {
          padding: 10px;
          border-radius: 50%;
          transition: background-color 0.3s ease;
          cursor: pointer;
          &:hover {
            background-color: #ffcfcf;
          }
        }
      }

      .pd-name {
        width: calc(30% - 10px);
        display: flex;
        font-weight: 700;
        align-items: flex-start;
        justify-content: flex-start;
        border-right: 1px solid #c6c7cf;
        padding: 5px 10px;
        font-size: 18px;

        @media screen and (max-width: 1024px) {
          font-size: 16px;
        }

        text-align: center;

        p {
          text-align: left;
          width: 100%;
          color: #000;
          margin: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .pd-image {
        width: calc(24% - 10px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #c6c7cf;
        padding: 5px 0;
        text-align: center;

        img {
          max-width: 80%;
          max-height: 200px;
        }
      }

      .pd-cat {
        width: calc(20% - 10px);
        color: #333f4e;
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #c6c7cf;
        padding: 5px 0;
        text-align: center;

        p {
          width: 100%;
          color: #000;
          margin: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .pd-price {
        width: calc(13% - 10px);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-right: 1px solid #c6c7cf;
        padding: 5px 10px;
        text-align: center;
        font-weight: 700;

        p {
          width: 100%;
          text-align: right;
          color: #333f4e;
          font-size: 18px;

          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }

          margin: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .small-label {
          display: block;
          text-align: left;
          margin-left: 10px;
          font-size: 14px;
          font-weight: normal;
          margin-top: 12px;
        }
      }

      .pd-count {
        width: calc(13% - 10px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #c6c7cf;
        padding: 5px 0;
        text-align: center;

        p {
          width: 100%;
          color: #000;
          margin: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &_content-search {
    margin-top: 40px;
    width: calc(100% - 40px);

    @media screen and (max-width: 900px) {
      width: 100%;
    }

    border: 1px solid rgb(87, 81, 81);
    border-bottom: none;
    border-radius: 5px;
    list-style-type: none;

    .select-modal {
      cursor: pointer;
    }

    .select-modal:hover {
      background-color: rgb(219, 241, 255);
    }

    li {
      width: 100%;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid rgb(87, 81, 81);

      .cat-name {
        width: calc(50% - 125px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgb(87, 81, 81);
        padding: 5px 0;
        text-align: center;

        a {
          text-decoration: none;
          width: 100%;
          color: #000;
          margin: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        a:hover {
          color: #9978e2;
        }
      }

      .cat-image {
        width: calc(50% - 125px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgb(87, 81, 81);
        padding: 10px;

        img {
          max-width: 80%;
          max-height: 200px;
        }
      }

      .cat-list {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgb(87, 81, 81);

        ul {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          a {
            text-decoration: none;
            width: 100%;
            color: #000;
            margin: 10px 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          a:hover {
            color: #9978e2;
          }
        }
      }

      .cat-tools {
        width: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 0;

        .active-selector {
          padding: 10px;
          border-radius: 50%;
          transition: background-color 0.3s ease;
          cursor: pointer;
          &:hover {
            background-color: #bad5fa;
          }
        }

        .red-selector {
          padding: 10px;
          border-radius: 50%;
          transition: background-color 0.3s ease;
          cursor: pointer;
          &:hover {
            background-color: #ffcfcf;
          }
        }
      }

      .pd-name {
        width: calc(30% - 10px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgb(87, 81, 81);
        padding: 5px 0;
        text-align: center;

        p {
          width: 100%;
          color: #000;
          margin: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .pd-image {
        width: calc(24% - 10px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgb(87, 81, 81);
        padding: 5px 0;
        text-align: center;

        img {
          max-width: 80%;
          max-height: 200px;
        }
      }

      .pd-cat {
        width: calc(20% - 10px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgb(87, 81, 81);
        padding: 5px 0;
        text-align: center;

        p {
          width: 100%;
          color: #000;
          margin: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .pd-price {
        width: calc(13% - 10px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgb(87, 81, 81);
        padding: 5px 0;
        text-align: center;

        p {
          width: 100%;
          text-align: right;
          color: #333f4e;
          font-size: 18px;

          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }

          margin: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .small-label {
          display: block;
          text-align: left;
          margin-left: 10px;
          font-size: 14px;
          font-weight: normal;
          margin-top: 12px;
        }
      }

      .pd-count {
        width: calc(13% - 10px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgb(87, 81, 81);
        padding: 5px 0;
        text-align: center;

        p {
          width: 100%;
          color: #000;
          margin: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &-pagination {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-self: center;
    justify-content: center;

    div {
      color: black;
      padding: 8px 16px;
      text-decoration: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 2px;
    }

    div.active {
      background-color: #9978e2;
      color: white;
      border-radius: 5px;
    }

    div:hover:not(.active) {
      background-color: #ddd;
      border-radius: 5px;
    }

    .disabled {
      background-color: #eee;
      border-radius: 5px;
      cursor: not-allowed;
    }
  }
}

.create-cat-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;

  .close-div {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p {
      margin-right: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #6f7486;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
  }

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    font-size: 24px;
    padding-left: 20px;
    font-weight: normal;

    img {
      margin-right: 10px;
    }

    p {
      color: #000;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    @media screen and (min-width: 1600px) {
      font-size: 30px;
    }

    @media screen and (max-width: 1024px) {
      font-size: 20px;
    }
  }

  .content {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    .editor {
      background-color: #fff;
      padding: 0px 10px;
      height: 200px;
      overflow-x: auto;
      text-overflow: ellipsis;
      border: 1.5px solid #ae99e8;
      border-radius: 3px;

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #9978e2;
        border-radius: 2px;
      }
    }

    &_area {
      display: flex;
      align-items: flex-start;
      margin: 10px 0;
      width: 100%;

      .active-select {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        &-item {
          cursor: pointer;
          padding: 10px 20px;
          border-bottom: 2px solid #b0b9c4;

          p {
            border-bottom: 2px solid transparent;
            color: #333f4e;
            opacity: 0.8;
            letter-spacing: 0.5px;
          }
        }

        &-item:hover {
          background-color: #eee;
        }

        &-item-active {
          cursor: pointer;
          padding: 10px 20px;
          border-bottom: 4px solid #9978e2;

          p {
            color: #9978e2;
            font-weight: 700;
            letter-spacing: 0.5px;
          }
        }
      }

      & label {
        letter-spacing: 0.8px;
        font-size: 20px;
        color: #6f7486;
        opacity: 0.8;
        margin-right: 5px;
        cursor: pointer;
      }

      label.error {
        color: #c73f3f;
      }

      input {
        margin-left: auto;
        padding: 5px;
        font-size: 20px;
        letter-spacing: 0.5px;
        width: 50%;
        margin-right: 5px;
        font-weight: 600;
      }

      input.small {
        width: 70px;
      }

      input.error {
        border: 2px solid #c73f3f !important;
      }

      input:active,
      input:focus,
      textarea:focus,
      textarea:active {
        border: 2px solid #9978e2;
      }

      select {
        margin-left: auto;
        padding: 5px;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }

      & textarea {
        margin-left: auto;
        padding: 5px;
        font-size: 20px;
        letter-spacing: 0.5px;
        width: 50%;
        margin-right: 5px;
        height: 100px;
        font-weight: 600;
        resize: vertical;
      }

      @media screen and (max-width: 1200px) {
        label,
        input {
          font-size: 16px;
        }
      }
    }

    .example {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        font-size: 20px;

        @media screen and (max-width: 1200px) {
          font-size: 16px;
        }
      }

      img {
        max-width: 80%;
        max-height: 400px;
      }
    }

    .bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 20px;
      padding-right: 20px;

      .error {
        text-align: right;
        margin-right: 10px;
        color: #c73f3f;
        font-size: 14px;
      }

      .buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .confirm {
          padding: 8px 15px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          background-color: #9978e2;
          transition: background-color 0.2s ease;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          border-radius: 3px;
          margin: 20px 0;
          margin-right: 30px;

          img {
            margin-right: 10px;
          }
        }

        .confirm:hover {
          background-color: #ae99e8;
        }

        .disabled {
          background-color: #aaa !important;
          cursor: not-allowed;
          color: #fff !important;
          margin-right: 30px;

          img {
            margin-right: 10px;
          }
        }

        .disabled:hover {
          background-color: #aaa !important;
        }

        .cancel {
          background-color: transparent;
          border: 1px solid #9978e2;
          color: #9978e2;
        }
      }
    }

    button {
      padding: 8px 15px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: #9978e2;
      transition: background-color 0.2s ease;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      border-radius: 3px;
      margin: 20px 0;
    }

    button:hover {
      background-color: #ae99e8;
    }

    .disabled {
      background-color: #aaa !important;
      cursor: not-allowed;
      color: #fff !important;
    }

    .disabled:hover {
      background-color: #aaa !important;
    }
  }
}
