.consist-list-block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #73788c;
  border-radius: 2px;

  &__header {
    background-color: #fff;
    width: 100%;
    max-height: 230px;
    padding: 10px;
    border-bottom: 1px solid #73788c;

    &_title {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      width: 100%;

      & img {
        margin-right: 10px;
      }

      & h2 {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 20px;
      }
    }

    &_subtitle {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #73788c;
      font-size: 14px;
      font-weight: 400;

      & p {
        color: #73788c;
        opacity: 0.8;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        text-align: right;
      }

      &_right {
        display: flex;
        flex-direction: row;
        align-items: center;

        .mr {
          margin-right: 60px;

          @media screen and (max-width: 1120px) and (min-width: 1024px) {
            margin-right: 20px;
          }
        }
      }
    }
  }

  .deny-order {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    color: rgb(251, 226, 226);
    font-weight: 400;
    font-size: 14px;
    background-color: rgb(173, 61, 61);
    margin: 5px 0;
    border: none;
    outline: none;
  }

  &__body {
    background-color: #e8edf3;
    position: relative;
    flex: 1 1 auto;
    width: 100%;

    &_scrolled {
      height: calc(70vh - 118px);
      padding-bottom: 75px;
      min-height: 210px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #9978e2;
        border-radius: 2px;
      }
    }

    .scrollable-shadow {
      width: 100%;
      height: 20px;
      position: absolute;
      bottom: 0;
      background: linear-gradient(
        180deg,
        #73788c 0%,
        rgba(115, 120, 140, 0) 100%
      );
      opacity: 0.2;
      transform: rotate(180deg);
    }

    .client-info {
      width: 100%;
      height: 80px;
      position: absolute;
      overflow-y: auto;
      bottom: 0;
      background-color: #f6f8fa;
      border-top: 1px solid #d4d6dd;
      display: flex;
      flex-direction: column;
      padding: 15px;

      p {
        font-weight: 400;
        font-size: 12px;
        color: #5d728d;
      }

      .bazaniy_time {
        margin-top: 5px;
      }

      .comment {
        margin-top: 5px;
      }

      span {
        color: #5e6369;
        font-weight: bold;
        font-size: 16px;
        margin-left: 10px;
      }

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #9978e2;
        border-radius: 2px;
      }
    }

    @media screen and (min-width: 1600px) {
      &_scrolled {
        padding-bottom: 95px;
      }

      .client-info {
        height: 100px;

        p {
          font-size: 14px;
        }

        span {
          font-size: 18px;
        }
      }
    }
  }

  &__footer {
    width: 100%;
    background-color: #fff;
    padding: 2px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;

    button {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 10px;
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      background-color: #9978e2;
      border: none;
      outline: none;

      span {
        margin-left: 10px;
      }
    }

    &_right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: auto;

      p {
        font-size: 14px;
        color: #898fa5;
        letter-spacing: 0.5px;
      }

      span {
        color: #333f4e;
        text-align: right;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

.changeStatusBox {
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  .changeStatus_select {
    padding: 4px 1px;
  }

  .changeStatus_button {
    padding: 5px 8px;
    background-color: yellow;
    color: rgba(17,17,17,0.9);
    font-weight: 500;
    border: 1px solid rgba(17,17,17,0.1);
    margin-left: 10px;
  }
}
}
