.global-loader {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.781);
  display: flex;
  align-items: center;
  justify-content: center;
}
