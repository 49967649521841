.DateChecker {
  //margin: 26px 0;
  width: 242px;
  height: 48px;
  display: flex;
  align-items: center;
  //font-family: FuturaPT;
  font-size: 1.2rem;
  transition: hover 0.15s ease-in-out;

  .DatePickerWrap {
    width: 242px;
    height: 40px;
    box-sizing: border-box;
    //font-family: FuturaPT;
    font-size: 1.2rem;
    transition: hover 0.15s ease-in-out;

    .DatePickerBox {
      border-radius: 5px;
      border: 1px solid rgb(226, 228, 229);
      width: 100%;
      height: 100%;
      background-color: #fff;
      display: flex;
      color: rgb(17, 30, 41);
      box-sizing: border-box;
      position: relative;
      padding-top: 4px;

      .DateCheckerLeft {
        z-index: 2;
        width: 95%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .DateCheckerRight {
        z-index: 1;
        width: 55px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .DateCheckerIcon {
          position: relative;
          top: 0;
        }
      }

      .Popper {
        width: 330px;
      }

      .DatePicker {
        box-sizing: border-box;
        border: none !important;
        outline: none !important;
        background-color: inherit;
        width: 138%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 13px;
        font-size: 1rem;
        font-family: "Fira Sans", sans-serif;
        font-weight: 500;
      }

      .DateCheckerDescription {
        position: absolute;
        top: -5px;
        left: 6px;
        color: rgb(112, 120, 127);
        box-sizing: border-box;
        padding: 0 5px;
        line-height: 0.6;
        background-color: rgb(248, 248, 248);
        font-weight: 400;
        border-radius: 20px;
        font-size: 14px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        //border-top: 1px solid rgb(226,228,229);
      }
    }
  }
}
