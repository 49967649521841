@import "src/styles/variables.scss";

.administration {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 30px;
  padding-bottom: 30px;
  width: 100%;

  &_first {
    height: 100%;
    border: 1px solid #73788c;
    width: 33%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;

    &:first-child {
      flex: 1 1 auto;
    }
  }

  &_second {
    height: 100%;
    //min-width: 388px;
    width: 37%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    //height: 100%;

    &:first-child {
      flex: 1 1 auto;
    }
  }

  &_third {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;

    &:first-child {
      flex: 1 1 auto;
    }
  }
}

@media screen and (max-width: 1024px) {
  .administration {
    flex-direction: column;
    align-items: center;
    padding: 20px 0;

    &_first,
    &_second,
    &_third {
      width: 90%;
      margin: 0;
      margin-bottom: 20px;
    }
  }
}

.administration_create_shop_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  .close-div {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p {
      margin-right: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #6f7486;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
  }

  & h3 {
    align-self: center;
  }

  &_header {
    display: flex;
    margin-top: 30px;
    flex-direction: row;
    align-items: flex-start;

    &_left {
      width: 50%;
    }

    &_right {
      width: calc(50% - 15px);
      margin-left: 15px;

      &_images {
        display: flex;
        flex-direction: column;
        align-items: center;

        & p {
          margin: 10px 0;
        }

        img {
          max-width: 70%;
          max-height: 100px;
          margin-bottom: 10px;
        }
      }
    }
  }

  &_area {
    display: flex;
    align-items: center;
    margin: 10px 0;

    & label {
      letter-spacing: 0.8px;
      font-size: 14px;
      color: rgb(117, 117, 117);
      margin-right: 5px;
      cursor: pointer;
    }

    label.error {
      color: #c73f3f;
    }

    & input {
      margin-left: auto;
      padding: 5px;
      font-size: 16px;
      letter-spacing: 0.5px;
      width: 50%;
      max-width: 180px;
    }

    input.error {
      border: 2px solid #c73f3f !important;
    }

    & select {
      margin-left: auto;
      padding: 5px;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }

  &_area_small {
    display: flex;
    align-items: center;
    margin: 7px 0;

    & label {
      letter-spacing: 0.8px;
      font-size: 12px;
      color: rgb(117, 117, 117);
      margin-right: 5px;
      cursor: pointer;
    }

    label.error {
      color: #c73f3f;
    }

    & input {
      margin-left: auto;
      padding: 3px;
      font-size: 12px;
      letter-spacing: 0.5px;
      width: 50%;
      max-width: 180px;
    }

    input.error {
      border: 2px solid #c73f3f !important;
    }

    & select {
      margin-left: auto;
      padding: 5px;
      border-radius: 3px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }

  &_address {
    border-top: 1.5px solid #eee;
    border-bottom: 1.5px solid #eee;
    padding: 10px 0;
    margin-bottom: 3px;

    h4 {
      font-weight: 600;
      font-size: 14px;
      color: rgb(77, 74, 74);
    }
  }

  &_footer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .disabled {
      color: #fff;
      background-color: #aaa;
      cursor: not-allowed;
    }

    p {
      margin-bottom: 10px;
    }

    & button {
      padding: 5px 10px;
      border: none;
      background-color: $main_bg-purple_darkest;
      color: #fff;
      cursor: pointer;
    }

    & button:hover {
      background-color: $main_bg-purple_darkest-hover;
    }

    div {
      display: flex;

      .active {
        padding: 5px 10px;
        border: none;
        background-color: #9978e2;
        color: #fff;
        cursor: pointer;
      }

      .active:hover {
        background-color: #7457aa;
      }

      .red {
        cursor: pointer;
        border: none;
        padding: 5px 10px;
        margin-left: 10px;
        color: #c73f3f;
        border: 2px solid #c73f3f;
        background-color: transparent;
      }

      .disabled {
        color: #fff;
        background-color: #aaa;
        cursor: not-allowed;
      }
    }
  }
}
