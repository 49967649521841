@import "src/styles/variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  //font-family: 'Fira Sans', sans-serif;
  font-family: $main_fontFamily !important;
}

*:after,
*:before {
  box-sizing: border-box;
}

body {
  height: 100%;
}

html {
  height: 100%;
  overflow-y: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
