// common
$white: #ffffff;
$black: #000000;
$green: #086d52;
$green_light: #0b795c;

// main
//$main_bg-purple: #AD8CF6;
$main_bg-purple: #9978e2;
$main_bg-purple_darkest: #8260c4;
$main_bg-purple_darkest-hover: rgba(130, 96, 196, 0.9);
$main_bg-purple_light: #ae99e8;
$main_bg-purple_transparent: rgba(150, 117, 210, 0.15);
$main_fontFamily: "Onest";
$main_bg-black: #1b1d25;
$main_onBg-textColor: #fff;

$main_bg-orange: #f6a005;

// login page

//$main_onBg-textColor: #aaa;

// color
$color_grey: rgb(112, 120, 127);
$color_red: rgb(221, 60, 60);
$color_yellow: #f3f30e;
$color_lightBlack: rgb(39, 39, 39);
$color_mediumBlack: rgb(17, 30, 41);
$color_darkBlack: rgb(0, 0, 0);

// bg
$bg_linearG_green: linear-gradient(76.12deg, #168b86 -175.56%, #0b726a 177.37%);

// amedia
$aq_extra_large: 1600px;
$aq_large: 1280px;
$aq_medium: 1080px;
$aq_low_medium: 1024px;
$aq_less_medium: 800px;
$aq_small: 670px;
$aq_less_small: 570px;
