@import "src/styles/variables.scss";

.merchantNavigation {
  background-color: #fff;
  width: 100%;
  max-height: 230px;
  padding: 14px 10px;
  border-bottom: 1px solid #73788c;

  &__header {
    display: flex;
  }

  &__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    width: calc(50% - 4px);
    cursor: pointer;
    padding-bottom: 6px;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    transition: all 0.1s ease-in-out;

    & img {
      margin-right: 10px;
    }

    & img.personnelIcon {
      width: 24px;
      height: 24px;
    }

    & div.title {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 20px;
    }
  }

  &__tab_isActive {
    color: $main_bg-purple;
    border-bottom: 2px solid $main_bg-purple;
    transition: all 0.1s ease-in-out;
  }

  &__subtitle {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #73788c;
    font-size: 14px;
    font-weight: 400;
  }

  .search {
    width: 100%;
    align-self: center;
    background-color: rgba(237, 237, 237, 0.6);
    border-radius: 4px;
    padding: 7px 5px;
    display: flex;
    align-items: center;
    cursor: pointer;

    label {
      cursor: pointer;
    }

    img {
      margin-right: 10px;
    }

    input {
      width: 100%;
      text-overflow: ellipsis;
      border: 0px;
      background-color: transparent;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.5px;
      color: #5b5d65;
    }

    input::placeholder {
      text-overflow: ellipsis;
    }
  }
}
