@import "src/styles/variables.scss";

.profile {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  &_label {
    width: 30%;
    height: 100%;
    background-color: #edefef;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 70%;
      max-width: 300px;
    }
  }

  &_main {
    width: 70%;
    background-color: #fff;
    position: relative;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &_area {
      display: flex;
      margin: 20px 0;
      align-items: center;
      flex-direction: column;
      & label {
        letter-spacing: 0.8px;
        font-size: 14px;
        color: rgb(82, 80, 80);
        margin-right: 5px;
        cursor: pointer;
      }
      & input {
        margin-left: 20px;
        padding: 5px;
        font-size: 16px;
        letter-spacing: 0.5px;
        width: 100%;
        max-width: 240px;
      }
      span {
        font-size: 16px;
        font-weight: bold;
        color: rgb(82, 80, 80);
      }
      p {
        margin-top: 5px;
        margin-left: 10px;
        font-size: 16px;
        padding: 6px 15px;
        background-color: #eee;
        border-bottom: 2px solid $main_bg-purple;
      }
    }
    h1 {
      font-size: 30px;
      text-align: center;
      margin-bottom: 20px;
      span {
        font-size: 20px;
        font-weight: normal;
      }
    }
    h3 {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 30px;
    }
    .change_pass_active {
      background-color: $main_bg-purple_darkest;
      border: none;
      align-self: center;
      padding: 8px 20px;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.2s ease;
      margin-top: 10px;
      color: #fff;
    }
    .change_pass_dis {
      background-color: rgb(211, 210, 210);
      color: #fff;
      border: none;
      align-self: center;
      padding: 8px 20px;
      font-weight: bold;
      cursor: not-allowed;
      margin-top: 10px;
    }
    .change_pass_active,
    .change_pass_dis {
      font-size: 16px;
    }
    .change_pass_active:hover {
      background-color: $main_bg-purple;
    }

    @media screen and (max-width: 1024px) {
      h1 {
        margin-bottom: 30px;
      }
      h3 {
        margin-bottom: 30px;
      }
    }
    @media screen and (min-width: 1600px) {
      h1 {
        font-size: 2vw;
        span {
          font-size: 1.53vw;
        }
      }
      h3 {
        font-size: 1.6vw;
      }
      &_area {
        span,
        p {
          font-size: 1vw;
        }
        span {
          margin-left: 40px;
        }
      }
      .change_pass_active,
      .change_pass_dis {
        font-size: 0.8vw;
        padding: 10px 30px;
      }
    }
  }
}
