.shop-item-container {
  cursor: pointer;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-left: 6px solid transparent;
  align-items: center;
  border-bottom: 1px solid #73788c;
  background-color: #fff;
  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & h2 {
      font-size: 19px;
      color: #9978e2;
      letter-spacing: 0.2px;
      font-weight: 800;
      cursor: pointer;
    }
  }

  &__content {
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
    .li {
      display: flex;
      align-items: center;
      color: #333f4e;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.5px;
      margin-bottom: 5px;
      & img {
        margin-right: 8px;
      }
    }
    .food {
      margin-top: 10px;
    }
  }
}

.shop-item-container-selected {
  border-left: none;
  border-left: 6px solid #9978e2;
  background-color: #e9f2fe;
}
