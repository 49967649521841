.merchantInfo {
  width: 100%;
  min-height: calc(70vh + 133px);
  height: 100%;
  border: 1px solid #73788c;
  padding: 26px 20px 20px 20px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  &_title {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 20px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
}
