@import "src/styles/variables.scss";

.CommonMerchantForm {
  width: 100%;
  flex-direction: column;
  justify-content: center;

  .CommonMerchantFormBox {
    border-bottom: 1.5px solid #eee;
    border-top: 1.5px solid #eee;
    margin-bottom: 3px;
    padding: 10px 0;
  }

  .CommonMerchantForm_containerArea {
    width: 100%;
    align-items: center;
    margin: 10px 0;

    & label {
      letter-spacing: 0.8px;
      font-size: 14px;
      color: rgb(117, 117, 117);
      margin-right: 5px;
      cursor: pointer;
    }

    label.error {
      color: #c73f3f;
    }

    & input {
      width: 100%;
      max-width: 100%;
      margin-left: auto;
      padding: 5px;
      font-size: 16px;
      letter-spacing: 0.5px;
    }

    input.error {
      border: 2px solid #c73f3f !important;
    }

    & select {
      margin-left: auto;
      padding: 5px;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }

  &_area {
    display: flex;
    align-items: center;
    margin: 10px 0;

    & label {
      letter-spacing: 0.8px;
      font-size: 14px;
      color: rgb(117, 117, 117);
      margin-right: 5px;
      cursor: pointer;
    }

    label.error {
      color: #c73f3f;
    }

    & input {
      margin-left: auto;
      padding: 5px;
      font-size: 16px;
      letter-spacing: 0.5px;
      width: 50%;
      max-width: 180px;
    }

    input.error {
      border: 2px solid #c73f3f !important;
    }

    & select {
      margin-left: auto;
      padding: 5px;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }

  &_address {
    border-top: 1.5px solid #eee;
    border-bottom: 1.5px solid #eee;
    padding: 10px 0;
    margin-bottom: 3px;

    h4 {
      font-weight: 600;
      font-size: 14px;
      color: rgb(77, 74, 74);
    }
  }

  &_area_small {
    display: flex;
    align-items: center;
    margin: 7px 0;

    & label {
      letter-spacing: 0.8px;
      font-size: 12px;
      color: rgb(117, 117, 117);
      margin-right: 5px;
      cursor: pointer;
    }

    label.error {
      color: #c73f3f;
    }

    & input {
      margin-left: auto;
      padding: 3px;
      font-size: 12px;
      letter-spacing: 0.5px;
      width: 50%;
      max-width: 180px;
    }

    input.error {
      border: 2px solid #c73f3f !important;
    }

    & select {
      margin-left: auto;
      padding: 5px;
      border-radius: 3px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }

    .checkbox {
      display: flex;
      align-items: center;
      min-width: 290px;
      label {
        font-weight: 600;
        min-width: 270px;
        color: #111;
      }
      input {
        height: 14px;
        width: 14px;
        margin-left: 5px;
      }
    }
  }

  &_footer {
    margin-top: 20px;
    /*display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;*/

    .disabled {
      color: #fff;
      background-color: #aaa;
      cursor: not-allowed;
    }

    p {
      margin-bottom: 10px;
    }

    & button {
      padding: 5px 10px;
      border: none;
      background-color: $main_bg-purple-darkest;
      color: #fff;
      cursor: pointer;
      margin: 0 auto;
    }

    & button:hover {
      background-color: $main_bg-purple-darkest-hover;
    }

    div {
      display: flex;

      .active {
        padding: 5px 10px;
        border: none;
        background-color: #9978e2;
        color: #fff;
        cursor: pointer;
      }

      .active:hover {
        background-color: #7457aa;
      }

      .red {
        cursor: pointer;
        border: none;
        padding: 5px 10px;
        margin-left: 10px;
        color: #c73f3f;
        border: 2px solid #c73f3f;
        background-color: transparent;
      }

      .disabled {
        color: #fff;
        background-color: #aaa;
        cursor: not-allowed;
      }
    }
  }
}
