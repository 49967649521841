@import "../../../styles/variables";

.EditUserForm {
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #73788c;
  border-radius: 2px;
  min-height: calc(70vh + 133px);
  display: flex;
  flex-direction: column;
  height: 100%;

  h2 {
    text-align: center;
  }

  .FormSection {
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 2px solid rgb(221, 221, 221);

    .ImageBox {
      margin: 0 0 30px 0;
      width: 100%;
      max-width: 170px;

      .ImageWrap {
        width: 170px;
        height: 170px;
        border-radius: 5px;
        background-color: #c2c2c2;
        contain: content;
        display: flex;
        align-items: center;
        justify-content: center;

        label {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          text-align: center;
          color: #fff;

          span {
            max-width: 80px;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .label-for-file-p {
        font-size: 14px;
        letter-spacing: 0.8px;
        color: #757575;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  & h2 {
    align-self: center;
    margin-bottom: 30px;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9978e2;
    border-radius: 2px;
  }

  .FormArea {
    margin: 10px auto 0 auto;
    max-width: 370px;

    .FormSelectWrap {
      display: flex;
      align-items: center;
      margin-top: 22px;

      label {
        letter-spacing: 0.8px;
        font-size: 14px;
        color: rgb(117, 117, 117);
        margin-right: 5px;
        cursor: pointer;
      }

      select {
        margin-left: auto;
        padding: 5px;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        min-width: 150px;
      }
    }
  }

  .FormFooter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;

    .FormButton {
      cursor: pointer;
      width: 100px;
      outline: none;
      border: none;
      margin: 0 10px;
      border-radius: 3px;
      padding: 10px 15px;
      color: #fff;
      font-weight: bold;
      background-color: $main_bg-purple_darkest;

      &:hover {
        background-color: $main_bg-purple_darkest-hover;
      }
    }

    .red {
      background-color: #c73f3f;

      &:hover {
        background-color: #c73f3f;
      }
    }
  }
}
