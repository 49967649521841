@import "src/styles/variables.scss";

.PosterMerchantFormWrap {
  h2 {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .PosterMerchantForm {
    width: 430px;
    margin: 0 auto;
  }

  .ButtonConfirmWrap {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    .ButtonConfirm {
      transition: ease-in-out 0.1s;
      text-decoration: none;
      width: 100%;
      height: 40px;
      background-color: #f6a005;
      border-radius: 20px;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.2px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: orange;
      }
    }
  }
}
