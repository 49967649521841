@import "src/styles/variables.scss";

.OpeningHours {
  padding: 12px 0 8px 0;
  color: #757575;
  user-select: none;

  .OpeningHours_TitleBox {
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-weight: 400;
      font-size: 14px;
      //display: inline;
    }

    .OpeningHours_ButtonAllShops {
      position: relative;
      top: 1px;
      transition: ease-in-out 0.1s;
      text-decoration: none;
      background-color: $main_bg-purple-darkest;
      color: #fff;
      font-weight: 400;
      font-size: 11px;
      letter-spacing: 0.2px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;
      padding: 0 8px;
      border-radius: 5px;
      height: 20px;
      user-select: none;

      &:hover {
        //background-color: orange;
        background-color: $main_bg-purple-darkest-hover;
      }

      &:active {
        //background-color: rgba(255, 165, 0, 0.9);
        background-color: $main_bg-purple-darkest;
      }
    }
  }

  .OpeningHoursUnit {
    position: relative;
    /*left: -4px;
    width: calc(100% + 4px);*/
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 4px 6px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0);
    &:empty {
      height: 0;
      border: none;
    }

    .OpeningHoursUnit_Left {
      width: 140px;
      margin-right: 10px;

      .OpeningHoursUnit_Name {
        font-size: 1.1rem;
        font-weight: 600;
        color: #333f4e;
        margin-bottom: 2px;
      }

      .OpeningHoursUnit_CheckboxWrap {
        display: flex;
        align-items: center;
        margin-right: 12px;

        p {
          font-weight: 400;
          font-size: 0.75rem;
          margin-right: 6px;
          width: 79px;
        }

        input {
          height: 12px;
          width: 12px;
          margin-bottom: 2px;
        }
      }
    }

    .OpeningHoursUnit_Right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      /*span {
        position: relative;
        top: 10px;
      }*/

      .OpeningHoursUnit_TimeWrap {
        display: flex;
        align-items: center;
        margin-right: 10px;

        .OpeningHoursUnit_Start,
        .OpeningHoursUnit_End {
          width: 66px;
        }
      }
    }
  }
}

.OpeningHours_AddingButton {
  padding: 6px 10px;
  border: none;
  //background-color: #f6a005;
  background-color: #257df7;
  color: #fff;
  cursor: pointer;
  width: 230px;
  margin-top: 10px;
  border-radius: 4px;
  margin-bottom: 10px;

  &:hover {
    opacity: 0.95;
  }
  &:active {
    opacity: 1;
  }
}

.TimeInput {
  transition: all 0.1s ease-in-out;
  width: 100%;
  height: 35px;
  border-radius: 4px;
  border: 1px solid rgba(17, 17, 17, 0.2);
  text-align: center;
  font-size: 0.94rem;
  font-weight: 600;

  &:focus {
    border: 1px solid rgba(17, 17, 17, 0.5);
  }
}

.AllShopsPanel_CheckboxWrap {
  margin-right: 8px;
  width: calc(90% - 132px - 26px);
  min-width: 24px;
  height: 35px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  input[type="checkbox"] {
    display: none;
  }

  /* робимо label клікабельним та задаємо позицію */
  label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    /*padding-left: 40px;
    font-size: 10px;
    line-height:26px;*/
  }

  /* с помощью псевдоэлементов создаем место для галочки или кружочка  */
  label:before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    left: -22px;
    bottom: -12px;
    //background-color: rgba(238, 238, 238, 0.2);
    border: 1px solid rgba(17, 17, 17, 0.2);
  }
  .radio label:before {
    border-radius: 50%;
  }
  input[type="checkbox"]:checked + label:before {
    content: "\2713";
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    font-size: 36px;
    color: #257df7;
    text-align: center;
    line-height: 15px;
    border: 1px solid #257df7;
  }
}
