.personal-list-block {
  width: 100%;
  border: 1px solid #73788c;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  &__header {
    background-color: #fff;
    width: 100%;
    max-height: 230px;
    padding: 26px 10px 10px 10px;
    border-bottom: 1px solid #73788c;
    &_title {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      width: 100%;
      & img {
        margin-right: 10px;
      }
      & h2 {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 20px;
      }
    }
    &_subtitle {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #73788c;
      font-size: 14px;
      font-weight: 400;
      & p {
        color: #73788c;
        opacity: 0.8;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        letter-spacing: 0.5px;
      }
    }
  }

  &__body {
    //background-color: #E8EDF3;
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    &_scrolled {
      height: calc(70vh);
      min-height: 274px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #9978e2;
        border-radius: 2px;
      }
    }
    .scrollable-shadow {
      width: 100%;
      height: 20px;
      position: absolute;
      bottom: 0;
      background: linear-gradient(
        180deg,
        #73788c 0%,
        rgba(115, 120, 140, 0) 100%
      );
      opacity: 0.2;
      transform: rotate(180deg);
    }
  }

  &__footer {
    background-color: #fff;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding: 6px 20px;
    margin-top: auto;
    p {
      color: #9978e2;
      font-size: 16px;
      letter-spacing: 0.5px;
      margin-left: 10px;
      text-transform: capitalize;
    }
  }
}

.personal-list-block__body_empty {
  padding-top: 20px;
  margin-left: 10px;
}
